<template>
  <div
    class="history-block"
    :class="{
      'keep-show': keepShow,
      expanded: expanded,
      'no-sidebar': !showSidebar,
    }"
  >
    <div class="chart-toolbar">
      <!-- left section -->
      <div class="left-section d-flex align-items-center">
        <span class="obj-name ml-2 mr-3">
          {{ object.priority_name }}
        </span>
        <!-- Date Time -->
        <div class="chart-date-time mr-1">
          <date-picker
            v-model="chartPeriod"
            type="datetime"
            range
            format="DD-MM-YYYY HH:mm:ss"
            :placeholder="trans.history.date"
            :show-time-panel="showTimeRangePanel"
            :open.sync="open"
            :confirm="true"
            :lang="datePickerLang"
            range-separator=" - "
            @close="handleRangeClose"
          >
            <template v-slot:footer>
              <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                {{
                  showTimeRangePanel
                    ? trans.history.select_date
                    : trans.history.select_time
                }}
              </button>
            </template>
          </date-picker>
        </div>
        <!-- <div v-show="!showChart">Filter</div> -->

        <!-- object primary -->
        <div
          id="object-chart-primary"
          class="dropdown object-chart-primary mr-1"
          v-show="showChart"
        >
          <button
            class="btn btn-sm minor-text"
            type="button"
            id="dropdownPrimary"
            @click="togglePrimaryDropdown"
            v-click-outside="hidePrimaryDropdown"
          >
            <i
              class="fas mr-1"
              :class="[chart.primaryIcon, chart.primaryIconColor]"
            ></i>
            <span>{{
              chart.primaryText || trans.history.chart.speed_gps
            }}</span>
            <i
              class="fas fa-caret-down ml-1"
              :class="{ 'rotate-180': primaryDropdown }"
            ></i>
          </button>
          <transition name="fadeDrop">
            <ul class="custom-dropdown-menu" v-show="primaryDropdown">
              <li
                class="object-chart-primary-list-item"
                :class="[chart.primaryValue === 'gps' ? 'selected' : '']"
                @click="
                  primaryDropdownStatus(
                    'gps',
                    trans.history.chart.speed_gps,
                    'fa-tachometer-alt',
                    'text-success'
                  )
                "
              >
                <i class="fas fa-tachometer-alt text-success mr-1"></i>
                <span>{{ trans.history.chart.speed_gps }}</span>
              </li>

              <li
                class="object-chart-primary-list-item"
                :class="[chart.primaryValue === 'gps-rpm' ? 'selected' : '']"
                @click="
                  primaryDropdownStatus(
                    'gps-rpm',
                    trans.history.chart.can_rpm,
                    'fa-tachometer-alt',
                    'text-danger'
                  )
                "
              >
                <i class="fas fa-tachometer-alt text-danger mr-1"></i>
                <span>
                  {{ trans.history.chart.can_rpm }}
                </span>
              </li>

              <li
                class="object-chart-primary-list-item"
                :class="[chart.primaryValue === 'fuel-level' ? 'selected' : '']"
                @click="
                  primaryDropdownStatus(
                    'fuel-level',
                    trans.history.chart.fuel_level,
                    'fa-gas-pump',
                    'text-dark'
                  )
                "
              >
                <i class="fas fa-gas-pump text-dark mr-1"></i>
                <span>{{ trans.history.chart.fuel_level }}</span>
              </li>

              <li
                class="object-chart-primary-list-item"
                :class="[chart.primaryValue === 'temp' ? 'selected' : '']"
                @click="
                  primaryDropdownStatus(
                    'temp',
                    trans.history.chart.temp,
                    'fa-temperature-high',
                    'text-info'
                  )
                "
              >
                <i class="fas fa-temperature-high text-info mr-1"></i>
                <span>{{ trans.history.chart.temp }}</span>
              </li>

              <!--            <li class="object-chart-primary-list-item"-->
              <!--                :class="[chart.primaryValue === 'seal' ? 'selected': '']"-->
              <!--                @click="primaryDropdownStatus('seal', trans.history.chart.seal, 'fa-lock', 'text-dark')">-->
              <!--              <i class="fas fa-lock text-dark mr-1"></i>-->
              <!--              <span>{{ trans.history.chart.seal }}</span>-->
              <!--            </li>-->
            </ul>
          </transition>
        </div>
        <!-- object secondary -->
        <div
          id="object-chart-secondary"
          class="dropdown object-chart-secondary"
          v-show="showChart"
        >
          <button
            class="btn btn-sm minor-text"
            type="button"
            id="dropdownSecondary"
            @click="toggleSecondaryDropdown"
            v-click-outside="hideSecondaryDropdown"
          >
            <i
              class="fas mr-1"
              :class="[chart.secondaryIcon, chart.secondaryIconColor]"
              v-show="this.chart.secondaryValue !== 'ref'"
            ></i>
            <img
              src="/img/icons/beacons.svg"
              alt=""
              class="beacons-icon"
              v-show="this.chart.secondaryValue === 'ref'"
            />
            <span>{{ chart.secondaryText || trans.history.chart.engine }}</span>
            <i
              class="fas fa-caret-down ml-1"
              :class="{ 'rotate-180': secondaryDropdown }"
            ></i>
          </button>
          <transition name="fadeDrop">
            <ul class="custom-dropdown-menu" v-if="secondaryDropdown">
              <li
                class="object-chart-secondary-list-item"
                :class="[chart.secondaryValue === 'engine' ? 'selected' : '']"
                @click="
                  secondaryDropdownStatus(
                    'engine',
                    trans.history.chart.engine,
                    'fa-car',
                    'text-success'
                  )
                "
              >
                <i class="fa fa-car text-success mr-1"></i>
                <span>{{ trans.history.chart.engine }}</span>
              </li>
              <li
                class="object-chart-secondary-list-item"
                :class="[chart.secondaryValue === 'ref' ? 'selected' : '']"
                @click="
                  secondaryDropdownStatus(
                    'ref',
                    trans.history.chart.beacons,
                    'fa-snowflake',
                    'text-info'
                  )
                "
              >
                <img src="/img/icons/beacons.svg" alt="" class="beacons-icon" />
                <!--              <i class="far fa-snowflake text-info mr-1"></i>-->
                <span>{{ trans.history.chart.beacons }}</span>
              </li>
              <li
                class="object-chart-secondary-list-item"
                :class="[chart.secondaryValue === 'status' ? 'selected' : '']"
                @click="
                  secondaryDropdownStatus(
                    'status',
                    trans.history.chart.object_status,
                    'fa-stream',
                    'text-success'
                  )
                "
              >
                <i class="fas fa-stream text-success mr-1"></i>
                <span>{{ trans.history.chart.object_status }}</span>
              </li>
            </ul>
          </transition>
        </div>
        <router-link :to="{ name: reportBackTo }" v-if="backToReport">
          <button
            class="btn btn-light ml-3 back-to-report border-0"
            @click="closeHistoryBlock"
          >
            <i class="fas fa-long-arrow-alt-left mr-1"></i
            >{{ trans.history.chart.back_to_report }}
          </button>
        </router-link>
      </div>
      <!--=========== right section ===========-->
      <div class="right-section d-flex align-items-center">
        <!-- swicth to history -->
        <div v-show="showChart">
          <!-- chart reset -->
          <button
            class="btn btn-sm btn-light mr-1"
            :title="trans.history.chart.reset"
            @click="updateChart"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
          <button
            class="btn btn-sm btn-light mr-3"
            @click="switchHistoryTable"
            :title="trans.history.chart.switch_to_history"
          >
            <i class="fas fa-table mr-1"></i
            ><span v-text="trans.history.table.title"></span>
          </button>
        </div>

        <!-- history columns -->
        <div v-show="showHistoryTable">
          <!-- Expand -->
          <button
            class="btn btn-sm btn-light mr-1"
            @click="toggleExpand"
            :title="trans.history.table.expand"
          >
            <i
              class="fas"
              :class="[expanded ? 'fa-chevron-down' : 'fa-chevron-up ']"
            ></i>
          </button>
          <!-- Excel Button -->
          <button
            class="btn btn-sm btn-light mr-1"
            v-show="showHistoryTable"
            :title="trans.history.table.excel_export"
            @click="downloadExcel"
          >
            <i
              class="fas excel-btn"
              :class="[
                excelDownloading
                  ? 'fa-spinner fa-spin text-success'
                  : 'fa-file-excel',
              ]"
            ></i>
          </button>
          <button class="btn btn-sm btn-light mr-1" @click="toggleModal">
            <i class="fas fa-columns mr-1"></i>
            <span v-text="trans.history.table.columns"></span>
          </button>
          <!-- switch to charts -->
          <button
            class="btn btn-sm btn-light mr-3"
            @click="switchHistoryTable"
            :title="trans.history.table.switch_to_chart"
          >
            <i class="fas fa-chart-line mr-1"></i>
            <span v-text="trans.history.chart.title"></span>
          </button>
        </div>
        <!-- chart close -->
        <button
          class="btn btn-sm chart-close-btn"
          @click="closeHistoryBlock"
          :title="trans.history.close"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <!-- end toolbar -->
    </div>

    <history-loader :noData="noData" :loadData="loadData"></history-loader>

    <history-chart
      :object="object"
      :historyResponseData="historyResponseData"
      :chartPrimaryValue="chart.primaryValue"
      :chartSecondaryValue="chart.secondaryValue"
      v-if="historyResponseData.length"
      v-show="showChart"
      ref="historyChart"
    >
    </history-chart>

    <history-table
      :historyResponseData="historyData"
      :historyList="historyList"
      :noData="noData"
      :loadData="loadData"
      :showModal="showModal"
      @closeModal="toggleModal"
      @columns="allColumnsForExcel"
      @marker-checked="excelAddMarker"
      @marker-unchecked="excelRemoveMarker"
      @add-chunk-history-data="addChunkDataForInfiniteLoader"
      v-show="showHistoryTable"
      :key="historyTableKey"
    >
    </history-table>
  </div>
</template>

<script>
import api from "../../api";
import moment from "moment";
import HistoryChart from "./HistoryChart.vue";
import HistoryTable from "./HistoryTable.vue";
import HistoryLoader from "../global/HistoryLoader.vue";
import clickOutside from "vue-click-outside";

import { EventBus } from "../../bus";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HistoryChart,
    HistoryTable,
    HistoryLoader,
  },
  props: {
    object: Object,
  },
  data() {
    return {
      datePickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      historyResponseData: [],
      filteredHistoryResponseData: [],
      historyList: [],
      loadData: false,
      noData: false,
      keepShow: true,
      showChart: true,
      showHistoryTable: false,
      historyTableKey: 0,
      expanded: false,
      showModal: false,
      excelDownloading: false,

      chartPeriod: [moment().startOf("day").toDate(), moment().toDate()],
      showTimeRangePanel: false,
      open: false,

      chart: {
        primaryValue: "gps",
        primaryText: "",
        primaryIcon: "fa-tachometer-alt",
        primaryIconColor: "text-success",
        secondaryValue: "engine",
        secondaryText: "",
        secondaryIcon: "fa-car",
        secondaryIconColor: "text-success",
      },
      primaryDropdown: false,
      secondaryDropdown: false,

      backToReport: false,
      reportBackTo: "",

      historyColumnNames: [], // needed for History Excel Export
      excelPeriodFrom: null,
      excelPeriodTo: null,
      excelMarkers: [],

      historyListOffset: 200,
      historyListStart: 200,
      historyListEnd: 400,

    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "settings",
      "getReportPeriod",
      "getReportRouteName",
      "getWhoWasChartPeriod",
      "showSidebar",
    ]),
    trans() {
      return this.translations;
    },
    historyData() {
      return this.filteredHistoryResponseData.length
        ? this.filteredHistoryResponseData
        : this.historyResponseData;
    },
  },
  async created() {
    if (this.getReportPeriod.length > 0) {
      this.chartPeriod = [
        moment(this.getReportPeriod[0], "DD-MM-YYYY HH:mm:ss").toDate(),
        moment(this.getReportPeriod[1], "DD-MM-YYYY HH:mm:ss").toDate(),
      ];
      this.backToReport = true;
      this.reportBackTo = this.getReportRouteName;
    }
    if (this.getWhoWasChartPeriod.length > 0) {
      this.chartPeriod = [
        moment(this.getWhoWasChartPeriod[0], "DD-MM-YYYY HH:mm:ss").toDate(),
        moment(this.getWhoWasChartPeriod[1], "DD-MM-YYYY HH:mm:ss").toDate(),
      ];
    }

    await this.getHistoryData();
    this.updateTableData();
    this.updateRoute();
  },
  mounted() {
    EventBus.$on("chartZoomed", () => {
      this.excelMarkers = [];
    });
    EventBus.$on("noSidebar", () => {
      this.noSidebar = !this.noSidebar;
    });
    // Emitted from points page
    EventBus.$on("close-history-block", this.closeHistoryBlock);
  },
  methods: {
    ...mapActions([
      "createRoute",
      "removeHistoryRoute",
      "createRouteRunner",
      "removeRouteRunner",
    ]),
    updateTableData() {
      EventBus.$on("updateTableData", (data) => {
        this.historyTableKey++;

        let startTime = data[0].x;
        let endTime = data[data.length - 1].x;
        let arrayFirstElement = this.historyResponseData[0];
        let arrayLastElement =
          this.historyResponseData[this.historyResponseData.length - 1];
        let arrayStartTime = moment(
          arrayFirstElement.tx_date + " " + arrayFirstElement.tx_time
        ).valueOf();
        let arrayEndTime = moment(
          arrayLastElement.tx_date + " " + arrayLastElement.tx_time
        ).valueOf();
        if (startTime !== arrayStartTime && startTime !== arrayEndTime) {
          this.filteredHistoryResponseData = this.historyResponseData.filter(
            (row) => {
              let timestamp = moment(row.tx_date + " " + row.tx_time).valueOf();
              return timestamp >= startTime && timestamp <= endTime;
            }
          );
          this.historyList = [];
          this.setHistoryList();
        } else {
          this.historyList = [];
          this.filteredHistoryResponseData = [];
          this.setHistoryList();
        }
      });
    },
    updateRoute() {
      EventBus.$on("updateRoute", (data) => {
        this.removeHistoryRoute();
        this.createHistoryRoute(data);
      });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    async handleRangeClose() { 
      if (
        this.chartPeriod[0].getDate() === this.chartPeriod[1].getDate() &&
        this.chartPeriod[0].getHours() === 0
      ) {
        this.chartPeriod[0] = moment(this.chartPeriod[0])
          .startOf("day")
          .toDate();
        this.chartPeriod[1] = moment(this.chartPeriod[1]).endOf("day").toDate();
      }
      this.showTimeRangePanel = false;
      await this.getHistoryData();
    },
    async updateChart() {
      await this.getHistoryData();
    },
    async getHistoryData() {
      if (!this.chartPeriod[0] || !this.chartPeriod[1]) {
        return;
      }
      this.loadData = true;
      await api
        .get("history", {
          params: {
            from: moment(this.chartPeriod[0]).format("DD-MM-YYYY HH:mm:ss"),
            to: moment(this.chartPeriod[1]).format("DD-MM-YYYY HH:mm:ss"),
            vehicle_id: this.object.vid,
          },
        })
        .then((response) => {
          this.historyList = [];
          this.historyResponseData = response.data.data;
          this.$audit({
            object_id: this.object.vid,
            action: "history_report",
            value: {
              from: moment(this.chartPeriod[0]).format("DD-MM-YYYY HH:mm:ss"),
              to: moment(this.chartPeriod[1]).format("DD-MM-YYYY HH:mm:ss"),
            },
          });

          if (!this.historyResponseData.length) {
            this.loadData = false;
            this.noData = true;
            this.removeHistoryRoute();
            return;
          }

          this.getDistance();
          this.setHistoryList();
          this.removeHistoryRoute();
          this.createHistoryRoute(this.historyResponseData);
          this.removeRouteRunner();
          this.createRouteRunner();

          this.loadData = false;
          this.noData = false;
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
          this.loadData = false;
          this.noData = true;
        });
    },
    allColumnsForExcel(payload) {
      this.historyColumnNames = [];
      let columns = [];
      payload.forEach((section) => {
        columns.push(section.columns);
      });
      columns.forEach((item) => {
        this.historyColumnNames.push(
          ...item.map((row) => {
            return {
              name: row.dataName,
              enabled: row.enabled,
            };
          })
        );
      });
    },
    excelAddMarker({ data, id }) {
      this.excelMarkers.push({
        lat: data.lat,
        lon: data.lon,
        id: id,
      });
    },
    excelRemoveMarker(markerId) {
      this.excelMarkers = this.excelMarkers.filter(
        (marker) => marker.id != markerId
      );
    },
    downloadExcel() {
      const history = this.historyData;
      this.excelPeriodFrom = `${history[0].tx_date} ${history[0].tx_time}`;
      this.excelPeriodTo = `${history[history.length - 1].tx_date} ${
        history[history.length - 1].tx_time
      }`;

      this.excelDownloading = true;
      api
        .get("history/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.excelPeriodFrom,
            to: this.excelPeriodTo,
            vehicle_id: this.object.vid,
            // criteria
            columns: this.historyColumnNames,
            markers: this.excelMarkers,
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const objectName = this.settings.is_vp
            ? this.object.reg_no
            : this.object.priority_name;
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.trans.history.history} ${objectName} ${moment(
            this.chartPeriod[0]
          ).format("YYYYMMDD HHmmss")} - ${moment(this.chartPeriod[1]).format(
            "YYYYMMDD HHmmss"
          )}.xlsx`;
          link.click();
          link.remove();

          this.$audit({
            object_id: this.object.vid,
            action: "history_report_excel",
            value: {
              from: this.excelPeriodFrom,
              to: this.excelPeriodTo,
              columns: this.historyColumnNames,
              markers: this.excelMarkers,
            },
          });
        })
        .catch((error) => {
          this.excelDownloading = false;
          console.error(error);
        });
    },
    setHistoryList() {
      let sliced = this.historyData.slice(0, this.historyListStart);
      this.historyList.push(...sliced);
    },
    addChunkDataForInfiniteLoader() { // emitted from history table to load more data
      let sliced = this.historyData.slice(
        this.historyListStart,
        this.historyListEnd
      );
      this.historyList.push(...sliced);
      this.historyListStart += this.historyListOffset;
      this.historyListEnd += this.historyListOffset;
    },
    createHistoryRoute(data) {
      let route = [];
      let parking = [];
      for (let i = 0; i < data.length; i++) {
        let row = data[i];
        if (row.lat != 0 && row.lon != 0) {
          route.push({ lat: parseFloat(row.lat), lng: parseFloat(row.lon) });
          if (row.event === "reason_stopped") {
            parking.push({ lat: row.lat, lon: row.lon });
          }
        }
      }

      this.createRoute({
        objectId: this.object.vid,
        route: route,
        parking: parking,
        update: false,
      });
    },
    switchHistoryTable() {
      this.showHistoryTable = !this.showHistoryTable;
      this.showChart = !this.showChart;
      this.expanded = false;
      // hide - back to report button - on history table toolbar
      if (this.showHistoryTable == false) {
        if (this.getReportPeriod.length > 0) {
          this.backToReport = true;
        }
      } else {
        this.backToReport = false;
      }
    },
    switchChart() {
      this.showChart = !this.showChart;
      this.showHistoryTable = !this.showHistoryTable;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    primaryDropdownStatus(type, text, iconClass, iconColorClass) {
      this.chart.primaryValue = type;
      this.chart.primaryText = text;
      this.chart.primaryIcon = iconClass;
      this.chart.primaryIconColor = iconColorClass;
    },
    secondaryDropdownStatus(type, text, iconClass, iconColorClass) {
      this.chart.secondaryValue = type;
      this.chart.secondaryText = text;
      this.chart.secondaryIcon = iconClass;
      this.chart.secondaryIconColor = iconColorClass;
    },
    closeHistoryBlock() {
      this.keepShow = false;
      this.showHistoryTable = false;
      this.expanded = false;
      this.removeHistoryRoute();
      this.removeRouteRunner();

      // remove active class from main chart button side menu
      EventBus.$emit("historyBlockClose");
      this.$emit("historyBlockClose");
    },
    getDistance() {
      let distance = 0;
      for (let i = 0; i < this.historyResponseData.length; i++) {
        let previousRow = this.historyResponseData[i - 1];
        let currentRow = this.historyResponseData[i];
        if (
          i !== 0 &&
          currentRow.lat != 0 &&
          currentRow.lon != 0 &&
          previousRow.lat != 0 &&
          previousRow.lon != 0
        ) {
          const start = new google.maps.LatLng({
            lat: parseFloat(previousRow.lat),
            lng: parseFloat(previousRow.lon),
          });
          const end = new google.maps.LatLng({
            lat: parseFloat(currentRow.lat),
            lng: parseFloat(currentRow.lon),
          });
          distance +=
            google.maps.geometry.spherical.computeDistanceBetween(start, end) /
            1000;
        }

        currentRow.driven_km = distance.toFixed(3);
        this.setCheckboxParam(currentRow);
      }
    },
    setCheckboxParam(row) {
      row.checkbox = false;
    },
    togglePrimaryDropdown() {
      this.primaryDropdown = !this.primaryDropdown;
    },
    hidePrimaryDropdown() {
      this.primaryDropdown = false;
    },
    toggleSecondaryDropdown() {
      this.secondaryDropdown = !this.secondaryDrop;
    },
    hideSecondaryDropdown() {
      this.secondaryDropdown = false;
    },
  },
  directives: {
    clickOutside,
  },
};
</script>
