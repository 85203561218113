<template>
  <div id="user-loginas-list" class="info-window">
    <div class="sticky d-flex align-items-center justify-content-between border-0 mb-1 px-2 shadow-sm">
      <i 
          class="fa fa-chevron-circle-right close-slide-container-icon"
          @click="closeComponent"
      ></i>
      <h5 class="slide-heading flex-grow-1 text-center">
          <i class="fas fa-users mr-1 text-success"></i>
          {{ trans.login_as.login_as  }}
      </h5>
    </div>
    <div class="login-as-list-container">
      <div class="login-as-search">
        <input
            v-model="treeFilter"
            type="text"
            class="js-login-as-filter flex-grow-1 border w-100 pl-4 py-1 rounded small shadow-sm"
            :placeholder="trans.login_as.find"
            ref="inputSearch"
          />
        <i class="fas fa-search loginas-filter-icon text-muted"></i>
      </div>
      <tree :data="loginTreeView"
            :filter="treeFilter"
            :options="treeOptions"
            v-if="loginTreeView.length"
            @node:selected="loginAs">
      </tree>
    </div>
    <div
        class="spinner-grow text-primary float-right mr-3 mt-n1 loading"
        role="status"
        v-if="!loginTreeView.length"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import api from "../../api";
import LiquorTree from "liquor-tree";

export default {
  components: {
    [LiquorTree.name]: LiquorTree,
  },
  data() {
    return {
      loginTreeView: [],
      treeFilter: '',
      treeOptions: {
        filter: {
          emptyText: ''
        },
        propertyNames: {
          text: 'name',
          children: 'child'
        }
      },
    };
  },
  computed: {
    ...mapGetters(['translations']),
    trans() {
      return this.translations.navbar.user_dropdown;
    },
    nothingFound() {
      return this.translations.vehicles.nothingFound
    }
  },
  created() {
    this.getUsers();
    this.treeOptions.filter.emptyText = this.trans.login_as.no_results
  },
  mounted() {
    this.$refs.inputSearch.focus();
    this.treeOptions.filter.emptyText = this.nothingFound
  },
  methods: {
    ...mapActions(["setUser"]),
    async getUsers() {
      api.get("users")
      .then((response) => {
        for (const obj in response.data.data) {
          this.loginTreeView.push(response.data.data[obj]);
        }
        this.loginTreeView.sort((a, b) => {
          return a.name.localeCompare(b.name)
        });
      })
      .catch((error) => {
        this.handleErrorMixin(error)
        console.error(error);
      });
    },
    async loginAs(node) {
      const userId = node.id
      if (node.parent === null) {
        return
      }

      try {
        const response = await api.post('login-as', {
          id: userId,
        })
        const responseData = response.data.data;

        if (responseData.token) {
          this.setUser({
            auth: true,
            token: responseData.token,
            user: responseData.user
          });
          this.$router.go(this.$router.currentRoute);
        } else {
          localStorage.removeItem('user');
          this.$router.push({name: 'login', query: {error: responseData}});
        }
      } catch (e) {
        this.handleErrorMixin(e)
        console.error(e)
      }
    },
    closeComponent() {
      this.$emit("close-component");
    },
  },
};
</script>
