<template>
  <div id="map-toolbar" class="map-toolbar shadow">
    <!-- send location toast -->
    <p class="send-location-success" :class="{ show: locationSent }">
      {{ trans.map.location_sent_successfully }}
    </p>
    <!-- map type button -->
    <div class="btn-group mr-1">
      <button
        id="map-type-button"
        class="btn btn-sm toolbar-btn map-type-btn"
        type="button"
        @click="showMaptypeMenu = !showMaptypeMenu"
        v-click-outside="hideMaptypeMenu"
        v-tooltip.left="{
          content: trans.map.toolbar.map_type.tooltip,
          theme: 'main',
        }"
      >
        <img :src="mapTypeSelectedImg" />
        <span
          id="map-type-name"
          class="minor-text map-type-name"
          v-text="mapTypeSelected"
        ></span>
        <i
          class="fas fa-caret-down ml-2"
          :class="{ 'rotate-180': showMaptypeMenu }"
        ></i>
      </button>
      <transition name="fadeDrop">
        <div class="py-0 map-type-dropdown shadow" v-if="showMaptypeMenu">
          <ul
            id="map-types-list"
            class="
              list-unstyled
              d-flex
              flex-column
              ml-0
              mb-0
              map-types-list-item map-type
            "
          >
            <div v-for="(type, index) in mapsType" :key="index">
              <li
                :class="{ selected: type.selected }"
                @click="selectMapType(type)"
              >
                <div class="d-flex align-items-center">
                  <div class="img-wrapper">
                    <img :src="`/img/icons/48x48/map-type-${type.id}.png`" />
                  </div>
                  <div class="pl-3">
                    <span class="header-text">{{ type.name }}</span>
                    <span class="sub-text">{{ type.trans }}</span>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </transition>
    </div>
    <!-- map LAYER button-->
    <div class="btn-group mr-1">
      <button
        class="btn btn-sm toolbar-btn map-type-btn"
        type="button"
        @click="showLayerMenu = !showLayerMenu"
        v-click-outside="hideLayerMenu"
        v-tooltip.left="{
          content: trans.map.toolbar.map_layer.tooltip,
          theme: 'main',
        }"
      >
        <img
          src="/img/icons/32x32/map-layers-latvia.png"
          class="mr-1"
          v-show="layerLatviaSelected"
        />
        <img
          src="/img/icons/32x32/map-layers-traffic.png"
          v-show="layerTraficSelected"
          class="mr-1"
        />
        <img
          src="/img/icons/48x48/map-type-waze.png"
          v-show="wazeEventsActive"
        />
        <img
          src="/img/icons/32x32/map-layers.png"
          v-show="!layerLatviaSelected && !layerTraficSelected && !wazeEventsActive"
        />
        <span
          id="map-layer-name"
          class="map-layer-name minor-text"
          :class="{ 'd-none': layerLatviaSelected || layerTraficSelected || wazeEventsActive }"
          v-text="trans.map.toolbar.map_layer.layers"
        >
        </span>
        <i
          class="fas fa-caret-down ml-2"
          :class="{ 'rotate-180': showLayerMenu }"
        ></i>
      </button>
      <transition name="fadeDrop">
        <div class="py-0 layers-dropdown-menu shadow" v-if="showLayerMenu">
          <ul
            class="
              list-unstyled
              d-flex
              flex-column
              ml-0
              mb-0
              map-types-list-item
            "
          >
            <!-- map-layers-latvia -->
            <li
              class="map-layer"
              :class="{ selected: layerLatviaSelected }"
              @click="latvianLayer"
            >
              <div class="d-flex align-items-center">
                <div class="img-container mr-2">
                  <img src="/img/icons/48x48/map-layers-latvia.png" />
                </div>
                <div class="text-container">
                  <span
                    class="header-text"
                    v-text="trans.map.toolbar.map_layer.latvian_territories"
                  ></span>
                  <span
                    class="sub-text"
                    v-text="trans.map.toolbar.map_layer.latvian_sub_text"
                  ></span>
                </div>
              </div>
            </li>
            <!-- map-layers-traffic -->
            <li
              class="map-layer"
              :class="{ selected: layerTraficSelected }"
              @click="addTrafficLayer"
              v-tooltip.right="{
                content: trans.map.toolbar.map_layer.only_with_google,
                theme: 'main',
              }"
              v-if="showGoogle"
            >
              <div class="d-flex align-items-center">
                <div class="img-container mr-2">
                  <img src="/img/icons/48x48/map-layers-traffic.png" />
                </div>
                <div class="text-container">
                  <span
                    class="header-text"
                    v-text="trans.map.toolbar.map_layer.traffic"
                  ></span>
                  <span
                    class="sub-text"
                    v-text="trans.map.toolbar.map_layer.traffic_sub_text"
                  >
                  </span>
                </div>
              </div>
            </li>
            <!-- Waze alerts control -->
            <li
              class="map-layer"
              :class="{ selected: wazeEventsActive }"
              @click="enableWazeEvents"
            >
              <div class="d-flex align-items-center">
                <div class="img-container mr-2">
                  <img src="/img/icons/48x48/map-type-waze.png" />
                </div>
                <div class="text-container">
                  <span
                    class="header-text"
                    v-text="trans.map.toolbar.map_layer.waze"
                  ></span>
                  <span
                    class="sub-text"
                    v-text="trans.map.toolbar.map_layer.waze_sub_text"
                  >
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <!-- object name on map -->
    <div class="btn-group mr-1">
      <button
        id="object-name-on-map"
        class="btn btn-sm toolbar-btn map-layer-btn"
        type="button"
        v-click-outside="hidePriorityNameDropdown"
        v-tooltip.left="{
          content: trans.map.toolbar.priority_name.tooltip,
          theme: 'main',
        }"
        @click="showPriorityName = !showPriorityName"
      >
        <i class="fas fa-location-arrow text-success smallest"></i>
        <i class="fas fa-marker text-muted smallest mr-1"></i>
        <i
          class="fas fa-caret-down ml-1"
          :class="{ 'rotate-180': showPriorityName }"
        ></i>
      </button>
      <transition name="fadeDrop">
        <div
          class="py-0 checkboxes-dropdown-container shadow"
          v-show="showPriorityName"
          @click="stopPropagation"
        >
          <h6 class="my-2 text-center minor-text">
            {{ trans.map.toolbar.priority_name.title }}
          </h6>
          <div class="d-flex justify-content-around mb-2">
            <!-- name -->
            <div class="align-items-baseline d-flex py-1">
              <input
                type="checkbox"
                id="priority-name-name"
                class="mr-1"
                @change="setPriorityNameOnMap"
                v-model="priorityName.name"
              />
              <label for="priority-name-name" class="mb-0">
                <span>{{ trans.map.toolbar.priority_name.name }}</span>
              </label>
            </div>
            <!-- Reg No -->
            <div class="py-1 d-flex align-items-baseline">
              <input
                type="checkbox"
                id="priority-name-regno"
                ref="regNo"
                class="mr-1"
                :disabled="regNoDisabled"
                @change="setPriorityNameOnMap"
                v-model="priorityName.regNo"
              />
              <label for="priority-name-regno" class="mb-0">
                <span>{{ trans.map.toolbar.priority_name.reg_no }}</span>
              </label>
            </div>
            <!-- Trailer -->
            <div
              class="py-1 d-flex align-items-baseline"
              v-if="settings.sm_object_trailer_number_show == 1"
            >
              <input
                type="checkbox"
                id="priority-name-trailer"
                class="mr-1"
                @change="setPriorityNameOnMap"
                v-model="priorityName.trailer"
              />
              <label for="priority-name-trailer" class="mb-0">
                <span>{{ trans.map.toolbar.priority_name.trailer }}</span>
              </label>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Broke/Join clusters button -->
    <button
      class="toolbar-btn clusters-btn mr-1"
      :class="{ active: clusterState }"
      @click="setClusterState(!clusterState)"
      v-tooltip.bottom="{
        content: trans.map.toolbar.cluster.tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/cluster.png" />
    </button>
    <!-- Who was there? button -->
    <button
      class="toolbar-btn who-was-there-btn mr-1"
      :class="{ active: showWhoWasThereSidebar }"
      v-tooltip.bottom="{
        content: trans.map.toolbar.who_was_there.tooltip,
        theme: 'main',
      }"
      @click="toggleWhoWasThereSidebar"
    >
      <i class="fas fa-map-marked-alt"></i>
    </button>
    <!-- ShareLocation button -->
    <button
      v-if="settings.is_emy"
      class="toolbar-btn mr-1 share-location-btn"
      :class="{ active: locationShareActive }"
      v-tooltip.bottom="{
        content: trans.map.toolbar.share_location.tooltip,
        theme: 'main',
      }"
      @click="shareLocation"
    >
      <i class="fas fa-map-marker-alt location-icon"></i>
      <i class="fas fa-share share-icon"></i>
    </button>

    <!-- Search toolbar -->
    <map-toolbar-search v-if="showSearch"></map-toolbar-search>
  </div>
</template>

<script>
import { EventBus } from "../../bus";
import api from "../../api";
import { mapActions, mapGetters } from "vuex";
import MapToolbarSearch from "./MapToolbarSearch.vue";
import clickOutside from "vue-click-outside";
import { ok } from "assert";

export default {
  components: {
    MapToolbarSearch,
  },
  data() {
    return {
      showPriorityName: false,
      checked: false,
      regNoDisabled: false,

      trafficLayer: new google.maps.TrafficLayer(),
      mapsType: [],

      mapTypeSelected: "Google",
      mapTypeSelectedImg: "/img/icons/32x32/map-type-roadmap.png",
      showMaptypeMenu: false,

      savedSelectedMap: null,

      layerLatviaSelected: false,
      layerTraficSelected: false,
      showLayerMenu: false,
      wazeEventsActive: false,

      showWhoWasThereSidebar: false,
      locationShareActive: false,
      locationShareAddress: "",
      locationSent: false,
      markers: [],
    };
  },
  computed: {
    ...mapGetters([
      "settings",
      "translations",
      "user",
      "map",
      "clusterState",
      "priorityName",
    ]),
    trans() {
      return this.translations;
    },
    showGoogle() {
      return this.user.permission && this.user.permission["sm.maps.google"];
    },
    showSearch() {
      return this.user.permission && this.user.permission["sm.maps.search"];
    },
  },
  created() {
    this.getMaps();
  },
  mounted() {
    EventBus.$on("closeWhoWasThere", this.closeAndDestroy);
    this.shareLocationLogic();
    if (this.$route.name === 'who-was-there') {
      this.showWhoWasThereSidebar = true
    }

    // Run waze data if it was saved 
    const wazeData = JSON.parse(localStorage.getItem('wazeData'))
    if (wazeData !== null) {
      this.wazeEventsActive = true
      setTimeout(() => { this.$emit('triggerWazeEvents', this.wazeEventsActive); }, 500)
    }
  },
  methods: {
    ...mapActions([
      "setClusterState",
      "updateMarkerLabel",
      "updatePriorityName",
      "createAddressMarker",
      "removeAddressMarker",
    ]),
    async getMaps() {
      const savedSelectedMap = JSON.parse(localStorage.getItem("selectedMap"));
      const settingSavedDefaultMapID = this.settings.sm_map_default;
      await api
        .get("maps")
        .then((response) => {
          this.mapsType = response.data.data;
          this.mapsType
            .sort((a, b) => a.sort - b.sort)
            .map((type) => {
              type.selected = type.id == (settingSavedDefaultMapID != null ? settingSavedDefaultMapID: 
              (savedSelectedMap != null ? savedSelectedMap.id : "roadmap")
              ) ? true: false
              type.trans = this.trans.map.map_type_list[type.id];
            });
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
        });

         // set selected map from setting if any
        if (settingSavedDefaultMapID != null) {
          this.mapButtonInfo(settingSavedDefaultMapID)
        }
        // set selected map from locale storage if any
        if (savedSelectedMap !== null && settingSavedDefaultMapID == null) {
          this.mapButtonInfo(savedSelectedMap.id)
        }
    },
    mapButtonInfo(mapId) {
      const index = this.mapsType.findIndex(item => item.id === mapId)
      const mapName = this.mapsType[index].name
     
      this.mapTypeSelected = mapName;
      this.mapTypeSelectedImg = `/img/icons/32x32/map-type-${mapId}.png`;
      this.map.setMapTypeId(mapId);
    },
    selectMapType(type) {
      this.mapsType.forEach((mapType) => {
        mapType.selected = false;
      }); // remove class selected
      type.selected = true;
      // set dropdown button to selected map

      this.mapTypeSelected = type.name;
      this.mapTypeSelectedImg = `/img/icons/32x32/map-type-${type.id}.png`;

      if (type.id === "roadmap") {
        this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      } else if (type.id === "satellite") {
        this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
      } else {
        // remove map trafic while click on other maps type
        if (this.layerTraficSelected) {
          this.layerTraficSelected = false;
          this.trafficLayer.setMap(null);
        }
        this.map.setMapTypeId(type.id);
      }

      // save selected map to local storage
      localStorage.setItem(
        "selectedMap",
        JSON.stringify({
          id: type.id,
          name: this.mapTypeSelected,
          selected: type.selected,
        })
      );
    },
    latvianLayer() {
      this.layerLatviaSelected = !this.layerLatviaSelected;
      if (this.layerLatviaSelected) {
        this.$router.push({
          name: "points",
          params: { latvianLayer: this.layerLatviaSelected },
        });
      } else {
        return;
      }
      // var layer = this.map.layers.latvia || null;
      // if (layer === null) {
      //   this.map.layers.latvia = new MapLayerLatvia();
      //   this.map.layers.latvia.init();
      //   this.$router.push({name: 'points', params: {latvianLayer: this.layerLatviaSelected}});
      // } else {
      //   if (this.layerLatviaSelected) {
      //     layer.addAll();
      //   } else {
      //     layer.removeAll();
      //   }
      // }
    },
    addTrafficLayer() {
      this.layerTraficSelected = !this.layerTraficSelected;
      this.trafficLayer.setMap(this.layerTraficSelected ? this.map : null);
      // switch to road map if it was not selected
      if (
        this.map.mapTypeId === "satellite" ||
        this.map.mapTypeId === "hybrid" ||
        this.map.mapTypeId === "roadmap"
      ) {
      } else {
        // add selected class to google roadmap
        this.mapsType.forEach((type) => {
          type.id == "roadmap"
            ? (type.selected = true)
            : (type.selected = false);
        });
        this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        this.mapTypeSelected = "Google";
        this.mapTypeSelectedImg = "/img/icons/32x32/map-type-roadmap.png";
      }
    },
    enableWazeEvents() {
      this.wazeEventsActive = !this.wazeEventsActive;
      this.$emit('triggerWazeEvents', this.wazeEventsActive);

      if (this.wazeEventsActive) {
        localStorage.setItem('wazeData', this.wazeEventsActive)
      } else {
        localStorage.removeItem('wazeData')
      }
    },
    setPriorityNameOnMap() {
      if (
        !this.priorityName.name &&
        !this.priorityName.regNo &&
        !this.priorityName.trailer
      ) {
        this.regNoDisabled = true;
        this.priorityName.regNo = true;
        this.$refs.regNo.checked = true;
      } else {
        this.regNoDisabled = false;
      }
      this.updatePriorityName(this.priorityName);
      this.updateMarkerLabel();
    },
    toggleWhoWasThereSidebar() {
      if (!this.showWhoWasThereSidebar) {
        this.showWhoWasThereSidebar = true;
        this.$router.push({ name: "who-was-there" });
        EventBus.$emit("historyBlockClose");
      } else {
        this.closeAndDestroy();
        EventBus.$emit("destroyShape");
      }
    },
    closeAndDestroy() {
      // who was there sidebar
      this.showWhoWasThereSidebar = false;
      this.$router.push({ name: "objects" }).catch(() => {});
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    hidePriorityNameDropdown() {
      this.showPriorityName = false;
    },
    hideLayerMenu() {
      this.showLayerMenu = false;
    },
    hideMaptypeMenu() {
      this.showMaptypeMenu = false;
    },
    shareLocation() {
      this.locationShareActive = !this.locationShareActive;
      // Share Location Logic in mounted hock

      if (this.locationShareActive) {
        // Remove EMY infoWindow and marker if there is any on map [ObjectSidebar]
        EventBus.$emit("close-EMY-infoWindow", this.locationShareActive);
      } else {
        this.removeInfoWindowAndMarker();
      }
    },
    removeInfoWindow() {
      let infoWindows = document.querySelectorAll(".gm-style-iw-t");
      infoWindows.forEach((win) => (win.style.display = "none"));
    },
    removeInfoWindowAndMarker() {
      this.removeInfoWindow();
      // remove markers
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i]) {
          this.markers[i].setMap(null);
          delete this.markers[i];
        }
      }
      this.markers = [];
    },
    async getLocationAddress(latLon) {
      try {
        const response = await api.get("maps/address", {
          params: {
            lat: latLon.lat.toFixed(6),
            lon: latLon.lng.toFixed(6),
          },
        });

        this.locationShareAddress = response.data.data.address;
      } catch (e) {
        this.handleErrorMixin(e)
        console.error(e);
      }
    },
    handleSendLocationButton(latLon) {
      let indexReg = /, LV-\d+/g;
      let address = this.locationShareAddress.replace(' novads', '').replace(' pagasts', '').replace(indexReg, '')
      setTimeout(() => {
        document.querySelector(".send-button").addEventListener("click", () => {
          api
            .post("maps/emy-gps-data", {
              lat: latLon.lat.toFixed(6),
              lon: latLon.lng.toFixed(6),
              address: address,
            })
            .then((response) => {
              this.removeInfoWindowAndMarker();
              this.locationShareActive = !this.locationShareActive;
              this.locationSent = true;
              setTimeout(() => {
                this.locationSent = false;
              }, 2000);
            })
            .catch((error) => {
              this.handleErrorMixin(error)
              console.error(error);
            });
        });
      }, 200);
    },
    shareLocationLogic() {
      setTimeout(() => {
        this.map.addListener("click", async (mapsMouseEvent) => {
          if (this.locationShareActive) {
            this.removeInfoWindowAndMarker();

            let latLon = JSON.parse(
              JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
            );
            await this.getLocationAddress(latLon);

            const marker = new google.maps.Marker({
              position: latLon,
              map: this.map,
              draggable: true,
            });

            this.markers.push(marker);

            let infoWindow = new google.maps.InfoWindow({
              content: `
                  <p class="text-monospace font-weight-500">
                    ${latLon.lat.toFixed(6)} - ${latLon.lng.toFixed(6)}
                  </p>
                  <p class="font-weight-bold mb-0"> ${
                    this.locationShareAddress
                  }</p>
                  <button class="btn btn-outline-primary btn-block mt-3 send-button">${
                    this.trans.map.location_send
                  }</button>
                `,
            });
            infoWindow.open(this.map, marker);
            infoWindow.addListener("closeclick", () => {
              marker.setMap(null);
            });

            marker.addListener("dragstart", () => {
              infoWindow.close();
            });
            // on marker drag
            marker.addListener("dragend", async () => {
              this.removeInfoWindow();
              latLon = {
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng(),
              };
              await this.getLocationAddress(latLon);
              infoWindow = new google.maps.InfoWindow({
                content: `
                    <p class="text-black-50 text-monospace">
                      ${latLon.lat.toFixed(6)} - ${latLon.lng.toFixed(6)}
                    </p>
                    <p class="font-weight-bold mb-0"> ${
                      this.locationShareAddress
                    }</p>
                    <button class="btn btn-outline-primary btn-block mt-3 send-button">
                      ${this.trans.map.location_send}
                    </button>
                `,
              });
              infoWindow.open(this.map, marker);
              infoWindow.addListener("closeclick", () => {
                marker.setMap(null);
              });
              this.handleSendLocationButton(latLon);
            });
            this.handleSendLocationButton(latLon);
          }
        });
      }, 2000);
    },
  },
  directives: {
    clickOutside,
  },
};
</script>
