<template>
  <div class="live-stream-info">
    <!-- header -->
    <div class="sticky d-flex align-items-center justify-content-between border-0 mb-1 px-2 shadow-sm">
        <button class="btn btn-sm d-flex menu-trigger" @click="closeComponent" v-if="streamCount > 0">
            <i class="fa fa-video smallest text-success"></i>
            <span class="stream-count" 
                v-if="streamCount > 0 && !showVideoContainer"
            >{{ streamCount }}</span>
        </button>
       
        <i 
            class="fa fa-chevron-circle-right close-slide-container-icon"
            @click="closeComponent"
        ></i>
        
        <h5 class="slide-heading flex-grow-1 text-center">
            <i class="fas fa-video mr-1 text-success"></i>
            {{ trans.live_stream || "Live Stream" }}
        </h5>
    </div>
    <!-- content -->
    <div class="py-2 px-1 stream-content">
        <Stream v-for="item in stream" 
            :key="item.id" 
            :streamInfoRow="item"
            :containerRef="`container-${item.id}`"
            :videoRef="`videoPlayer-${item.id}`"
        />
    
        <p v-if="noStream" 
            class="text-center text-monospace small text-danger py-4"
        >
            <i class="fas fa-road mr-1"></i>
            {{ trans.no_stream_found || 'No streams found!' }}
        </p>
    </div>
  </div>
</template>

<script>
    import {mapGetters} from "vuex"
    import Stream from './stream.vue'
export default {
    props: {
        stream: Array,
        streamCount: Number,
        showVideoContainer: Boolean
    },
    components: {
        Stream
    },
    computed: {
        ...mapGetters(["translations"]),
        trans() {
            return this.translations.video_stream || {}
        },
        noStream() {
            return !this.stream.length
        }
    },
    methods: {
        closeComponent() {
            this.$emit("close-stream-container");
        },
    }
}
</script>

<style>

</style>