export default {
    state: {
        period: [],
        reportRoute: "",
    },
    actions: {
        setReportPeriod(context, period) {
            context.commit("setReportPeriod", { period: period });
        },
        setReportRoute(context, reportRoute) {
            context.commit("setReportRoute", reportRoute);
        },
        resetReportPeriod(context) {
            context.commit('resetReportPeriod')
        }
    },
    mutations: {
        setReportPeriod(state, data) {
            state.period = data.period;
        },
        setReportRoute(state, reportRoutName) {
            state.reportRoute = reportRoutName;
        },
        resetReportPeriod(state) {
            state.period = []
        }
    },
    getters: {
        getReportPeriod(state) {
            return state.period;
        },
        getReportRouteName(state) {
            return state.reportRoute;
        },
    },
};