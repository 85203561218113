<template>
  <div class="search-wrapper">
    <!-- search DB results container -->
    <transition name="fade">
      <div
        class="search-results-container shadow-lg"
        v-if="showSearchContainer && search.length"
        ref="container"
      >
        <!-- address list -->
        <ul class="d-flex flex-column align-items-start list-unstyled mb-0">
          <li
            v-for="(address, index) in searchResults"
            :key="address.id"
            :class="{ selected: index === currentItem }"
            @click="goToAddress(address)"
          >
            <i class="fas fa-map-marker-alt mr-2"></i>
            <span>{{ getAddressName(address) }}</span>
          </li>
        </ul>
        <!-- no result found -->
        <p
          class="mb-0 p-2 text-danger font-weight-bold small"
          v-if="searchResults.length == 0"
        >
          <i class="fas fa-exclamation mr-1"></i>
          {{ trans.map.toolbar.search.no_search_results }}
        </p>
      </div>
    </transition>
    <!-- search Coords Container -->
    <transition name="fade">
      <div
        class="search-results-container shadow-lg p-2 pb-3"
        v-show="showCoordsContainer"
        ref="coordsContainer"
      >
        <!-- validation text & loader -->
        <div class="d-flex align-items-baseline justify-content-center">
          <p class="mt-2 mb-3 font-weight-600 small" 
              ref="coordsSuccessError"
              :class="validInvalidCoordsClass">
          </p>
          <p v-if="gettingAddressLoader" class="ml-2 text-success">
            <i class="fas fa-spinner fa-spin"></i>
          </p>
        </div>
        <div class="coords-error-correction border">
          <h5>{{ trans.map.toolbar.search.right_coords_pattern || 'Here is the right coordinates pattern:' }}</h5>
          <!-- WGS84 -->
          <div class="correction-line">
            <span class="coords-type">
              <span class="highlight-correct" :class="{'highlight': coordsType === 'WGS84'}">WGS84</span>:
            </span>
            <p>dd.dddddd dd.ddddddd</p>
          </div>
          <!-- LKS92 -->
          <div class="correction-line">
            <span class="coords-type">
              <span class="highlight-correct" :class="{'highlight': coordsType === 'LKS92'}">LKS92</span>:
            </span>
            <p>ddddd.d dddddd.d</p>
          </div>
          <!-- MGRS -->
          <div class="correction-line">
            <span class="coords-type">
              <span class="highlight-correct" :class="{'highlight': coordsType === 'MGRS'}">MGRS</span>:
          </span>
            <p>ddL LL dddd dddd</p>
          </div>
        </div>
      </div>
    </transition>
    <!-- google search input -->
    <div class="search-input small mr-1" v-show="searchMap === 'google'">
      <i class="fas fa-search-location search-icon"></i>
      <i
        class="fas fa-spinner fa-spin text-success search-loading"
        v-show="loading"
      ></i>
      <input
        type="text"
        v-model="search"
        size="32"
        class="map-search"
        :placeholder="trans.map.toolbar.search.enter_location"
        spellcheck="false"
        autocomplete="off"
        ref="googleSearchInput"
      />
      <i class="fa fa-times clear-search" @click="closeSearch"></i>
    </div>
    <!-- search input -->
    <div v-show="searchMap !=='google'" id="map-search-input" class="search-input small mr-1">
      <i class="fas fa-search-location search-icon"></i>
      <i
        class="fas fa-spinner fa-spin text-success search-loading"
        v-show="loading"
      ></i>
      <input
        type="text"
        size="32"
        v-model="search"
        @input="handleSearch"
        class="map-search"
        :placeholder="trans.map.toolbar.search.enter_location"
        spellcheck="false"
        autocomplete="off"
        ref="searchInput"
      />
      <i class="fa fa-times clear-search" @click="closeSearch"></i>
    </div>
    <button
      class="toolbar-btn search-elem mr-1"
      @click="setSearchMap('maplant')"
      :class="{ active: searchMap == 'maplant' }"
      v-if="settings.sm_map_search_db == 1"
      v-tooltip.bottom="{
        content: trans.map.toolbar.search.latvia_tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/map-type-maplant.png" />
    </button>

    <button
      class="toolbar-btn search-elem mr-1"
      @click="setSearchMap('google')"
      :class="{ active: searchMap == 'google' }"
      v-if="settings.sm_map_search_google == 1"
      v-tooltip.bottom="{
        content: trans.map.toolbar.search.google_tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/search-google.png" />
    </button>

    <button
      class="toolbar-btn search-elem mr-1"
      @click="setSearchMap('waze')"
      :class="{ active: searchMap == 'waze' }"
      v-if="settings.sm_map_search_waze == 1"
      v-tooltip.bottom="{
        content: trans.map.toolbar.search.waze_tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/search-waze.png" />
    </button>

    <button
      class="toolbar-btn search-elem mr-1"
      @click="setSearchMap('yandex')"
      :class="{ active: searchMap == 'yandex' }"
      v-if="settings.sm_map_search_yandex == 1"
      v-tooltip.bottom="{
        content: trans.map.toolbar.search.yandex_tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/search-yandex.png" />
    </button>

    <button
      class="toolbar-btn search-elem mr-1"
      @click="setSearchMap('coords')"
      :class="{ active: searchMap == 'coords' }"
      v-if="settings.sm_map_search_coords == 1"
      v-tooltip.bottom="{
        content: trans.map.toolbar.search.cord_tooltip,
        theme: 'main',
      }"
    >
      <img src="/img/icons/32x32/search-coords.png" />
    </button>

    <!-- <button class="square-button small-button xx"></button> -->
    <!-- <div id="search-results" class="search-results shadow"></div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "../../api";
import {LKS92WGS84} from '../../static/LKS92WGS84';
import MGRS from 'mgrs'

export default {
  data() {
    return {
      search: "",
      searchMap: "maplant",
      searchResults: [],
      showSearchContainer: false,
      loading: false,
      currentItem: 0,
      markers: [],
      showCoordsContainer: false,
      coordsType: '',
      coordsLatLon: null,
      coordsAddress: null,
      originalCoords: null,
      gettingAddressLoader: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "translations", "map"]),
    trans() {
      return this.translations;
    },
    validInvalidCoordsClass() {
      return {
        'text-success': this.coordsType === 'WGS84' || this.coordsType === 'LKS92' || this.coordsType === 'MGRS',
        'text-danger': this.coordsType === '' || this.search === ''
      }
    },
    setSuccessErrorCoordsMSG() {
      if (this.coordsType === '') {
        return this.trans.map.toolbar.search.unknown_coords || 'Unknown Coordinates!'
      } else {
        return `${this.coordsType} - ${this.trans.map.toolbar.search.valid_coords || 'Coordinates valid'}`
      }
    },
    cleanCoords() {
      let cleanCoords = this.search
      cleanCoords = cleanCoords.replaceAll(";"," ")
      cleanCoords = cleanCoords.replaceAll(","," ")
      cleanCoords = cleanCoords.replaceAll("  "," ")
      return cleanCoords;
    },
  },
  created() {
    document.addEventListener("keydown", this.handleKeydownOnSearch);
  },
  mounted() {
    setTimeout(() => { this.searchGoogle() }, 1000)
    this.map.addListener('zoom_changed', () => {
      
    })
  },
  methods: {
    ...mapActions(["panTo", "createAddressMarker"]),

    handleSearch() {
      switch (this.searchMap) {
        case 'maplant':
          this.searchDb();
          break;
        case 'coords':
          this.searchCoords()
          break;
      }
    },
    async searchDb() {
      if (this.search.length > 2) {
        this.loading = true;
        api.get("maps/search", {
            params: {
              address: this.search,
            },
          })
          .then((response) => {
            this.searchResults = response.data.data;
            this.showSearchContainer = true;
            this.loading = false;
            this.currentItem = 0;

            this.$audit({action: 'address_search', value: {search: this.search}});
          })
          .catch((error) => {
            this.handleErrorMixin(error)
            console.error(error);
          });
      }
    },
    searchCoords() {
      let coordsSuccessError = this.$refs.coordsSuccessError
      if (this.search.length > 2) {
        this.cleanCoords

        const WGSRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?) \s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/gi
        const LKSRegex = /^((\d{5,6})|(\d{5,6}\.\d{1})) ((\d{5,6})|(\d{5,6}\.\d{1}))$/gi
        const MGRSRegex = /^(((([1-5][0-9]?|^60)([^ABIOYZ.0-9][C-X]{0}))(?:[\s])?)(([^IO.0-9][A-Z]{0})([^IOWXYZ][A-V]{0})(?:[\s])?)(?:(([\d]{10}|(\s[0-9]{5}\s[0-9]{5}))|([\d]{8}|(\s[0-9]{4}\s[0-9]{4}))|([\d]{6}|(\s[0-9]{3}\s[0-9]{3}))|([\d]{4}|(\s[0-9]{2}\s[0-9]{2}))|([\d]{2}|(\s[0-9]{1}\s[0-9]{1}))))?)$/

        if (!this.cleanCoords.match(WGSRegex) || 
            !this.cleanCoords.match(LKSRegex) || 
            !this.cleanCoords.match(MGRSRegex)) 
        {
          this.coordsType = ''
          this.showCoordsContainer = true;
          coordsSuccessError.innerText = this.setSuccessErrorCoordsMSG // Unknown coordinates
        }
        //  WGS84
        if (this.cleanCoords.match(WGSRegex)) {
          this.coordsType = "WGS84"
          this.showCoordsContainer = true;
          coordsSuccessError.innerText = this.setSuccessErrorCoordsMSG;
        }
        // LKS92
        if (this.cleanCoords.match(LKSRegex)) {
          this.coordsType = 'LKS92'
          coordsSuccessError.innerText = this.setSuccessErrorCoordsMSG
        }
        // MGRS
        if (this.cleanCoords.match(MGRSRegex)) {
          this.coordsType = 'MGRS';
          coordsSuccessError.innerText = this.setSuccessErrorCoordsMSG
        }
      }
    },
    async searchGoogle() {
      let searchInput = this.$refs.googleSearchInput
      let autocomplete = new google.maps.places.Autocomplete(searchInput)
      autocomplete.bindTo('bounds', this.map)

      // needed fields from API
      autocomplete.setFields(['place_id', 'geometry', 'formatted_address'])

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace()
        const 
        { 
          place_id: id,
          geometry: { location: { lat, lng } }, 
          formatted_address: address 
        } = place

        this.createAddressMarker({
          id,
          lat: lat(),
          lon: lng(),
          address
        });

        this.panTo({
          lat: lat(),
          lon: lng()
        });
        this.map.setZoom(this.map.maxZoom)
        this.search = ''
      })
    },
    getAddressName(address) {
      let fullAddress = address.city ? address.city + "," : " ";
      fullAddress += address.novads ? address.novads + "," : " ";
      fullAddress += address.setellment ? address.setellment + "," : " ";
      fullAddress += address.street ? address.street + " " : ' ';
      fullAddress += address.house ? address.house : ' ';
      return fullAddress;
    },
    goToAddress(address) {
      this.createAddressMarker({
        id: address.id,
        lat: address.lat,
        lon: address.lon,
        address: this.getAddressName(address),
      });
      this.panTo({
        lat: address.lat,
        lon: address.lon,
      });
      this.map.setZoom(this.map.maxZoom)
      this.closeSearch();
    },
    closeSearch() {
      this.searchResults = []
      this.showSearchContainer = false;
      
      this.showCoordsContainer = false;
      this.coordsType = ''
      if (this.markers.length > 1) {
        this.removeMarkers()
      }
      this.search = "";
    },
    removeMarkers() {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null)
      }
      this.markers = []
    },
    async handleKeydownOnSearch(e) {
      let resultContainer = this.$refs.container;
      let selectedLi = document.querySelector(".selected");
      switch (e.code) {
        case 'Escape':
          this.closeSearch();
          if (this.markers.length) {
            this.removeMarkers()
          }
          break;
        case 'ArrowUp':
          if (this.currentItem > 0) {
            this.currentItem--;
          }
          break;
        case 'ArrowDown':
          if (this.currentItem < this.searchResults.length - 1) {
            this.currentItem++;
          }
          break;
        case 'Enter':
          if (this.searchMap === "maplant") {
            this.goToAddress(this.searchResults[this.currentItem]);
            this.closeSearch();
          }
          // HANDLE COORDINATES
          if (this.searchMap === "coords") {
            this.removeMarkers();
            // WGS84
            if (this.coordsType === 'WGS84') {
              this.originalCoords = this.search
              let coords = this.cleanCoords.split(" ")
              this.coordsLatLon = {
                lat: parseFloat(coords[0]),
                lng: parseFloat(coords[1]),
              }
              await this.getLocationAddress(this.coordsLatLon)
            }
            // LKS92
            if (this.coordsType === 'LKS92') {
              this.originalCoords = this.search
              let coords = this.cleanCoords.split(" ")
              let convertedLatLonArray = LKS92WGS84.convertXYToLatLon([coords[0], coords[1]]);
              this.coordsLatLon = {
                lat: parseFloat(convertedLatLonArray[0].toFixed(6)),
                lng: parseFloat(convertedLatLonArray[1].toFixed(6))
              }
              await this.getLocationAddress(this.coordsLatLon)
            }
            // MGRS 
            if (this.coordsType === 'MGRS') {
              this.originalCoords = this.search
              // remove spaces to be acceptable for MGRS plugin param
              let coords = this.cleanCoords.replaceAll(" ","");
              let convertedLatLonArray = MGRS.toPoint(coords)
              this.coordsLatLon = {
                lat: parseFloat(convertedLatLonArray[1].toFixed(6)),
                lng: parseFloat(convertedLatLonArray[0].toFixed(6))
              }
              await this.getLocationAddress(this.coordsLatLon)
            }
            // create marker & infoWindow
            const marker = new google.maps.Marker({
              position: this.coordsLatLon,
              map: this.map
            });
            this.markers.push(marker)

            let infoWindow = new google.maps.InfoWindow({
              content: `
                <p class="font-weight-500 text-muted text-monospace mb-2">${this.originalCoords}</p>
                <p class="text-success mb-1 font-weight-bold text-monospace">${this.coordsAddress}</p>
              `
            })
            infoWindow.open(this.map, marker);
            // Move map into marker
            this.panTo({
              lat: this.coordsLatLon.lat,
              lon: this.coordsLatLon.lng,
            });
            this.map.setZoom(this.map.maxZoom)
            infoWindow.addListener("closeclick", () => {
              this.removeMarkers()
            });
            this.closeSearch()
          }
          break;
        default:
          return;
      }
      // scroll container
      if (this.showSearchContainer) {
        resultContainer.scrollTop =
          selectedLi.offsetTop - resultContainer.offsetHeight / 2;
      }
    },
    async getLocationAddress(latLon) {
      this.gettingAddressLoader = true
      try {
        const response = await api.get("maps/address", {
          params: {
            lat: latLon.lat.toFixed(6),
            lon: latLon.lng.toFixed(6),
          },
        });
        if (response.data.data.valid) {
          this.coordsAddress = response.data.data.address;
        } else {
          this.coordsAddress = this.trans.map.toolbar.search.no_search_results
        }
        this.gettingAddressLoader = false
      } catch (e) {
        this.handleErrorMixin(e)
        console.error(e);
        this.gettingAddressLoader = false
      }
    },
    setSearchMap(searchMap) {
      this.searchMap = searchMap;
      this.closeSearch()
      this.$refs.searchInput.focus()
    }
  },
};
</script>
