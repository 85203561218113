<template>
    <div>
      <!-- HEADING -->
        <div class="video-title-and-close">
            <h6 class="video-title px-2 mb-0" 
                :class="[videoLoading? 'text-secondary': 'text-success']"
            >
                <i class="fa fa-car mr-1"></i>
                <span>{{ streamInfoRow.title }}</span>
            </h6>
            <div>
                <button 
                    class="btn btn-light btn-sm mr-2"
                    @click="requestSound"
                >
                    <i class="fas" :class="[isAudioMuted ? 'fa-microphone-slash': 'fa-microphone']"></i>
                </button>
                <button 
                    class="btn btn-light btn-sm video-close"
                    v-if="streamInfoRow.link"
                    @click="deleteStream(streamInfoRow.link)" 
                >{{ trans.stream.stop_watching }}</button>
            </div>
        </div>
        <!-- STREAM ROW -->
        <div class="live-stream-row shadow-sm" 
            :ref="containerRef" 
            v-if="streamInfoRow.src" 
            @mouseover="videoHovered = true"
            @mouseleave="videoHovered = false"
        >
            <div 
                v-if="ended"
                title="Ended"
                class="
                    video-loader
                    video-ended
                    h-100 w-100
                    d-flex align-items-center justify-content-center
                    text-secondary small
                " 
            >
                <i class="fas fa-video-slash"></i>
            </div>
            <div v-if="videoLoading" class="video-loader">
              <i class="fas fa-sync-alt fa-spin text-dark"></i>
            </div>
            <video  width="100%"
                :ref="videoRef"
                autoplay 
                controls 
            >
            </video>
            <!-- Custom video control -->
            <div class="video-custom-controls"
                :class="{'is-fullscreen': isFullscreen, 'show-controls': videoHovered}" 
                v-show="showCustomControls">
                <div class="control-left">
                    <!-- LIVE -->
                    <div class="live">
                        <span>LIVE</span>
                    </div>
                    <!-- volume control -->
                    <div
                        v-if="!isAudioMuted"
                        class="volume-controls d-flex align-items-center"
                    >
                        <i class="fas" :class="updateVolumeIcon()"  @click="toggleMute"></i>              
                        <input 
                        v-model="volume"
                        @input="updateVolume"
                        class="volume-input"  
                        type="range" 
                        max="1" 
                        min="0" 
                        step="0.01">
                    </div>
                </div>
                <div class="control-right">
                    <!-- resolution -->
                    <div class="resolutions" v-if="hasResolution">
                        <i class="fas fa-cog" 
                            @click="showResolution = !showResolution" 
                            v-click-outside="hideResolutions"
                            :class="{'text-white': showResolution}"
                        ></i>
                        <div class="available-resolutions-container" 
                            :class="{'show-resolutions': showResolution}"
                        >
                            <ul class="list-unstyled">
                                <li v-for="(resolution, index) in resolutions" 
                                    :key="index"
                                    :class="{'selected': resolution.is_active}"
                                    @click="changeResolution(resolution.link)"
                                >
                                    {{ resolution.title }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- detach -->
                    <i class="far fa-window-restore detach-screen"
                        @click="customPIP"
                    >
                    </i>
                    <!-- fullscreen -->
                    <i class="fas fullscreen" 
                        :class="[isFullscreen ? 'fa-compress-arrows-alt': 'fa-expand']" 
                        @click="toggleFullScreen"
                    >
                    </i>
                    
                </div>
            </div>
            <!-- pip overlay -->
            <div class="overlay" v-if="customPipOverlay">
                <i class="far fa-window-restore"></i>
                <p class="small">{{ trans.stream.pic_in_pic }}</p>
            </div>
        </div>
        <!-- placeholder -->
        <div v-else class="video-placeholder font-weight-bold">
            {{ streamInfoRow.message }}
        </div>
    </div>
  </template>
  
  <script>
  import Hls from 'hls.js'
  import VideoStreamMixin from '../../mixins/VideoStreamMixin';
  import clickOutside from "vue-click-outside";
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { EventBus } from '../../bus'
  import api from '../../api'
  import Swal from 'sweetalert2'
  export default {
        props: {
            streamInfoRow: Object,
            containerRef: String,
            videoRef: String
        },
        mixins: [VideoStreamMixin],
        data() {
            return {
                video: setTimeout(() => {this.$refs[this.videoRef]}, 1000),
                volume: 0.2,
                showCustomControls: false,
                isFullscreen: false,
                showResolution: false,
                videoLoading: true,
                stream: null,
                ended: false,
                videoHovered: false,
                isAudioMuted: this.streamInfoRow.is_audio_muted,

                hls: null,

                customPipOverlay: false,
                pipX: 0,
                pipY: 0
            }
        },
        mounted() {
            let unwatch = this.$watch('streamInfoRow', function(newVal, oldVal) {
                if ('src' in newVal && newVal.type == 'success') {
                    this.video = this.$refs[this.videoRef];
                    this.stream = this.streamInfoRow.src
                    this.hls = new Hls();
                    this.hls.loadSource(this.stream);
                    this.hls.attachMedia(this.video);
                    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        this.video.play()
                    })

                    const videoWorks = !!document.createElement('video').canPlayType;
                    if (videoWorks) {
                        this.video.controls = false;
                        this.showCustomControls = true
                        this.videoLoading = false
                    }
                    this.updateVolume()

                    document.addEventListener('fullscreenchange', this.handleControlsOnFullscreen);
                    document.addEventListener('webkitfullscreenchange', this.handleControlsOnFullscreen);
                    document.addEventListener('mozfullscreenchange', this.handleControlsOnFullscreen);
                    document.addEventListener('MSFullscreenChange', this.handleControlsOnFullscreen);
                    
                    this.video.onended = () => {
                        this.ended = true
                    }

                    unwatch()
                }
            })

            EventBus.$on('back-to-normal', (id) => {
                if (this.streamInfoRow.id === id) {
                    this.customPipOverlay = false
                    this.video.play()
                }
            })
        },
        computed: {
            ...mapGetters(["translations", "streamPIP"]),
            isVideoHere() {
                return this.streamInfoRow.src && this.streamInfoRow.type === 'success'
            },
            warning() {
                return this.streamInfoRow.type !== 'success'
            },
            trans() {
                return this.translations.vehicles
            },
            hasResolution() {
                return ("options" in this.streamInfoRow && this.streamInfoRow.options.length !== 0)
            },
            resolutions() {
                if ("options" in this.streamInfoRow && this.streamInfoRow.options.length !== 0) {
                    return this.streamInfoRow.options
                }
            },
        },
        methods: {
            ...mapActions(["getStream"]),
            ...mapMutations(["removeStream", "setCustomPIP", "removeSinglePIP"]),
            async deleteStream(url) {
                if (url === 'undefined') {
                    return;
                }
                try {
                    const response = await api.delete(url)
                    const responseStreamID = response.data.stream.id

                    this.hls.destroy()
                    this.hls.off(Hls.Events.MEDIA_ATTACHED);
                    this.hls.off(Hls.Events.MANIFEST_PARSED);
                    this.hls.off(Hls.Events.LEVEL_LOADED);
                    this.hls.off(Hls.Events.LEVEL_UPDATED);
                    this.hls.off(Hls.Events.ERROR);

                    this.removeStream(responseStreamID)
                    if (this.streamPIP.length > 0) {
                        this.removeSinglePIP(responseStreamID)
                    }
                    this.getStream()
                    
                } catch (e) {
                    this.handleErrorMixin(e)
                }
            },
            customPIP() {
                this.customPipOverlay = true
                this.video.pause()
                this.setCustomPIP({
                    id: this.streamInfoRow.id,
                    title: this.streamInfoRow.title,
                    src: this.streamInfoRow.src,
                    sourceType: this.streamInfoRow.source_type,
                })
            },
            async requestSound() {
                if (this.isAudioMuted === true) {
                    Swal.fire({
                        title: this.trans.stream.requesting_audio,
                        text: this.streamInfoRow.title,
                        position: 'top',
                        icon: 'question',
                        showConfirmButton: true,
                        showCancelButton: true,
                        width: '25rem',
                        confirmButtonColor: 'green',
                        confirmButtonText: this.trans.ok,
                        cancelButtonText: this.trans.close,
                        customClass: {
                            confirmButton: 'btn',
                            cancelButton: 'btn',
                            icon: 'swal-custom-icon'
                        }
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            try {
                                const response = await api.patch(this.streamInfoRow.link, { audio: true })
                                if (response.status === 200) {
                                    this.isAudioMuted = false
                                }
                            } catch(e) {
                                this.handleErrorMixin(e)
                            }
                        }
                    })
                } else {
                    try {
                        const response = await api.patch(this.streamInfoRow.link, { audio: false })
                        if (response.status === 200) {
                            this.isAudioMuted = true
                        }
                    } catch(e) {
                        this.handleErrorMixin(e)
                    }
                }

            },
            async changeResolution(link) {
                try {
                    const response = await api.post(link)
                } catch(e) {
                    this.handleErrorMixin(e)
                }
            }
        },
        directives: {
            clickOutside,
        },
  }
  </script>
  
  <style lang="scss" scoped>
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 555;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  </style>