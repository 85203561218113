<template>
  <div>
    <div id="page-reports-vue" class="page-container reports-container">
      <div class="reports-sidebar" :class="{ 'no-sidebar': hideReportSidebar }">
        <div class="list-unstyled">
          <router-link
            :to="{ name: 'trip-sheet' }"
            class="reports-sidebar-group-header"
            v-if="showTripSheet"
          >
            <div class="icon-wrapper"><i class="fas fa-car"></i></div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.trip_sheet.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.trip_sheet.sub_title
              }}</span>
            </div>
          </router-link>
          <!-- Mileage by day -->
          <router-link
            :to="{ name: 'mileage-by-day' }"
            class="reports-sidebar-group-header"
            v-if="showMileageByDay"
          >
            <div class="icon-wrapper"><i class="fas fa-calendar-alt"></i></div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.mileage_by_day.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.mileage_by_day.sub_title
              }}</span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'smart-temp' }"
            class="reports-sidebar-group-header"
            v-if="showTemperature"
          >
            <div class="icon-wrapper">
              <i class="fas fa-temperature-high"></i>
            </div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.temperature.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.temperature.sub_title
              }}</span>
            </div>
          </router-link>
          <!-- Over speed -->
          <router-link
            :to="{ name: 'over-speed' }"
            class="reports-sidebar-group-header"
            v-if="showOverSpeed"
          >
            <div class="icon-wrapper">
              <i class="fas fa-tachometer-alt"></i>
            </div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.over_speed.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.over_speed.sub_title
              }}</span>
            </div>
          </router-link>
          <!-- Sensor report -->
          <router-link
            :to="{ name: 'sensor-status' }"
            class="reports-sidebar-group-header"
            v-if="showSensorStatus"
          >
            <div class="icon-wrapper">
              <!-- <i class=" fas fa-lightbulb"></i> -->
              <img src="/img/icons/beacons-sidebar.svg" alt="" />
            </div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.sensor_status.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.sensor_status.sub_title
              }}</span>
            </div>
          </router-link>
          <!-- Fuel report -->
          <router-link
            :to="{ name: 'fuel-report' }"
            class="reports-sidebar-group-header"
            v-if="showFuelReport"
          >
            <div class="icon-wrapper">
              <i class="fa fa-gas-pump"></i>
            </div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{
                trans.side_menu.fuel_report.title
              }}</span>
              <span class="reports-sidebar-group-subtitle">{{
                trans.side_menu.fuel_report.sub_title
              }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <transition name="slide-right" v-if="showAll">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../bus";

export default {
  components: {},
  data() {
    return {
      hideReportSidebar: false,
    };
  },
  computed: {
    ...mapGetters(["translations", "user"]),
    trans() {
      return this.translations.reports;
    },
    showAll() {
      return (
        this.showTripSheet ||
        this.showMileageByDay ||
        this.showTemperature ||
        this.showOverSpeed ||
        this.showSensorStatus
      );
    },
    showTripSheet() {
      return (
        this.user.permission && this.user.permission["sm.reports.trip_sheet"]
      );
    },
    showMileageByDay() {
      return (
        this.user.permission &&
        this.user.permission["sm.reports.mileage_by_day"]
      );
    },
    showTemperature() {
      return (
        this.user.permission && this.user.permission["sm.reports.temperature"]
      );
    },
    showOverSpeed() {
      return (
        this.user.permission && this.user.permission["sm.reports.over_speed"]
      );
    },
    showSensorStatus() {
      return (
        this.user.permission && this.user.permission["sm.reports.sensor_status"]
      );
    },
    showFuelReport() {
      return (
        this.user.permission &&
        this.user.permission["sm.reports.fuel_card_full_report"]
      );
    },
  },
  mounted() {
    EventBus.$on("report-fullscreen", (payload) => {
      this.hideReportSidebar = payload;
    });
  },
};
</script>
