<template>
  <div class="c-modal-overlay" @click.self="closeModal">
    <div class="c-modal calendar-range-modal">
      <!-- header -->
      <div class="c-modal-header shadow-sm">
        <div class="caption">
          <span class="flex-grow-1 pl-1 text-left font-weight-bold">
            <i class="fas fa-calendar-alt fa-sm mr-1"></i>
            <span class="small font-weight-600">{{
              calendarTrans.select_period_rang
            }}</span>
          </span>
          <i class="fas fa-times close-modal" @click="closeModal"></i>
        </div>
      </div>
      <!-- body -->
      <div class="c-modal-body">
        <div class="row">
          <div class="col-md-3">
            <ul class="list-unstyled mb-md-0">
              <li
                @click="today"
                :class="[activePeriod === 'today' ? 'active' : '']"
              >
                {{ calendarTrans.today }}
              </li>
              <li
                @click="yesterday"
                :class="[activePeriod === 'yesterday' ? 'active' : '']"
              >
                {{ calendarTrans.yesterday }}
              </li>
              <li
                @click="currentWeek"
                :class="[activePeriod === 'currentWeek' ? 'active' : '']"
              >
                {{ calendarTrans.current_week }}
              </li>
              <li
                @click="previousWeek"
                :class="[activePeriod === 'prevWeek' ? 'active' : '']"
              >
                {{ calendarTrans.previous_week }}
              </li>
              <li
                @click="last7Days"
                :class="[activePeriod === 'last7days' ? 'active' : '']"
              >
                {{ calendarTrans.last_7_days }}
              </li>
              <li
                @click="currentMonth"
                :class="[activePeriod === 'currentMonth' ? 'active' : '']"
              >
                {{ calendarTrans.current_month }}
              </li>
              <li
                @click="previousMonth"
                :class="[activePeriod === 'prevMonth' ? 'active' : '']"
              >
                {{ calendarTrans.previous_month }}
              </li>
              <li
                @click="last30Days"
                :class="[activePeriod === 'last30days' ? 'active' : '']"
              >
                {{ calendarTrans.last_30_days }}
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <date-picker
              value="dates"
              ref="calendar"
              is-range
              mode="date"
              :first-day-of-week="2"
              :locale="user.language"
              v-model="dates"
              :max-date="new Date()"
              @input="handleHoursDate"
            >
            </date-picker>
            <div class="custom-time-picker">
              <div>
                <date-picker mode="time" 
                  is24hr v-model="timeFrom" 
                  :validHours="timeStartValidHours"
                  :locale="user.language"
                />
              </div>
              <div>
                <date-picker mode="time" 
                  is24hr v-model="timeTill" 
                  :validHours="timeEndValidHours"
                  :locale="user.language"
                />
              </div>
            </div>
    </div>
        </div>
      </div>
      <!-- footer -->
      <div class="c-modal-footer bg-light mt-2 py-2 justify-content-between">
        <button class="btn btn-success w-25" @click="sendPeriodToParent">
          <i class="fas fa-check mr-1"></i>{{ calendarTrans.ok }}
        </button>
        <button class="btn btn-light" @click="closeModal">
          <i class="fas fa-times mr-1 text-danger"></i>{{ calendarTrans.close }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      timeFrom: moment().startOf("day").toDate(),
      timeTill: moment().toString(),
      activePeriod: "today",
      dates: {
        start: moment().startOf("day").toDate(),
        end: moment().toDate(),
      },
    };
  },
  computed: {
    ...mapGetters(["translations", "user"]),
    calendarTrans() {
      return this.translations.reports.calendar;
    },
    formattedStartDate() {
      return `${moment(this.dates.start).format("DD-MM-YYYY")} ${moment(this.timeFrom).format("HH:mm:ss")}`
    },
    formattedEndDate() {
      return `${moment(this.dates.end).format("DD-MM-YYYY")} ${moment(this.timeTill).format("HH:mm:ss")}`
    },
    timeStartValidHours() {
      let isCurrentDate = moment().isSame(this.dates.start, 'day')
      let obj;
      if (isCurrentDate) {
        obj = {min: 0, max: moment().hour()}
      } else {
        obj = {min: 0, max: 24}
      }
      return obj
    },
    timeEndValidHours() {
      let isCurrentDate = moment().isSame(this.dates.end, 'day')
      let obj;
      if (isCurrentDate) {
        obj = {min: 0, max: moment().hour()}
      } else {
        obj = {min: 0, max: 24}
      }
      return obj
    },
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.isShown = false;
        this.$emit("calendarClosed", this.isShown);
      }
    });
  },
  methods: {
    // show correct date in hours section
    handleHoursDate() {
      let isCurrentDate = moment().isSame(this.dates.end, 'day')
      if (isCurrentDate) {
        this.timeTill = moment().format()
      } else {
        this.timeTill = moment(this.dates.end).endOf('day').toDate()
      }
      this.timeFrom = this.dates.start
    },
    async today() {
      this.activePeriod = "today";
      this.dates = {
        start: moment().startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
      await this.$refs.calendar.move(new Date());
    },
    async yesterday() {
      this.activePeriod = "yesterday";
      this.dates = {
        start: moment().subtract(1, "day").startOf("day").toDate(),
        end: moment().subtract(1, "day").endOf("day").toDate(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    async currentWeek() {
      this.activePeriod = "currentWeek";
      this.dates = {
        start: moment().startOf('isoWeek').toDate(),
        end: moment().format()
      }
      await this.$refs.calendar.move(this.dates.start);
    },
    async previousWeek() {
      this.activePeriod = "prevWeek";
      this.dates = {
        start: moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
        end: moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    async last7Days() {
      this.activePeriod = "last7days";
      this.dates = {
        start: moment().subtract(7, "day").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    async currentMonth() {
      this.activePeriod = "currentMonth";
      this.dates = {
        start: moment().startOf("month").toDate(),
        end: moment().format(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    async previousMonth() {
      this.activePeriod = "prevMonth";
      this.dates = {
        start: moment().subtract(1, "month").startOf("month").toDate(),
        end: moment().subtract(1, "month").endOf("month").toDate(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    async last30Days() {
      this.activePeriod = "last30days";
      this.dates = {
        start: moment().subtract(30, "day").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
      await this.$refs.calendar.move(this.dates.start);
    },
    sendPeriodToParent() {
      this.$emit("selectedPeriod", {
        start: this.formattedStartDate,
        end: this.formattedEndDate,
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit("calendarClosed");
    },
  },
};
</script>
