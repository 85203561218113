// fontawesome
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";

// App
import "./assets/scss/app.scss";

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import DatePicker from "vue2-datepicker";
import VTooltip from "v-tooltip";
import audit from "@/mixins/audit";
import helper from './helper';

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

// Global error handling mixin
import HandleErrorMixin from './mixins/handleErrorMixin';
Vue.mixin(HandleErrorMixin)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Vue.config.productionTip = false;

Vue.use(Toast);


import axios from "axios";
import GmapVue from 'gmap-vue'
Vue.use(DatePicker);
Vue.use(VTooltip, {
  themes: {
    main: {
      $extend: "tooltip",
      $resetCss: true,
      html: true,
      delay: { show: 1000 },
    },
  },
});
Vue.mixin(audit);
new Vue({
  router,
  store,
  async created() {
    await axios.get("/config.json")
    .then(response => {
      Vue.use(GmapVue, {
        load: {
          key: response.data.APP_GOOGLE_API_KEY,
          libraries: 'places,geometry',
          autoBindAllEvents: true,
          installComponents: true,
          dynamicLoad: false,
        }
      })
    })
  },
  render: (h) => h(App),
}).$mount("#app");
