import axios from "axios";
import store from "./store";
import Swal from 'sweetalert2';

const api = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(request => {
    request.baseURL = store.getters.config.APP_API_URL;
    
    if (store.getters.isAuthenticated) {
        request.headers.common.Authorization = `Bearer ${store.getters.accessToken}`;
    }

    return request;
});

api.interceptors.response.use((response) => {
        if(response.data.message) {
            Swal.fire({
                toast: true,
                showConfirmButton: false,
                position: 'top-end',
                width: '25rem',
                timer: 10000,
                timerProgressBar: true,
                showCloseButton: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                },
                customClass: {
                    timerProgressBar: 'progress-warning',
                    popup: 'p-2'
                },
                icon: 'warning',
                title: 'Warning',
                color: 'chocolate',
                text: response.data.message
            })
        }  
        return response
    }, async function (error) {
        if (error.response.status === 401) {
            this.$store.dispatch('removeAllIntervals');
            this.$store.dispatch.setUser({auth: false, token: null, user: {}});
        } else {
            store.dispatch('apiConnectionFailed')
        }
        return Promise.reject(error);
    }
);

export default api;
