<template>
  <div>
    <div class="all-columns-checkbox">
      <i
        class="fas fa-angle-right trigger-arrow mr-2"
        :class="{ shown: show }"
        @click="toggleList"
      ></i>
      <input
        class="mr-2"
        :id="data.id"
        type="checkbox"
        v-model="isChecked"
        v-indeterminate="data.indeterminate"
        @change="setCheckboxStatus"
      />
      <label :for="data.id">{{ data.name }}</label>
    </div>
    <div class="checkbox-container" v-show="show">
      <div
        v-for="(column, index) in data.columns"
        :key="index"
        class="form-check mb-2 ml-2"
      >
        <input
          class="js-history-column-checkbox mr-2"
          type="checkbox"
          :value="column.dataName"
          :id="column.dataName"
          v-model="column.enabled"
          @change="indeterminateEnabledColumns"
        />
        <label class="form-check-label" :for="column.dataName"
          >{{ column.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: Object,
  },
  data() {
    return {
      isChecked: false,
      data: this.category,
      show: this.category.show,
    };
  },
  created() {
    this.indeterminateEnabledColumns();
  },
  methods: {
    toggleList() {
      this.show = !this.show;
    },
    setCheckboxStatus() {
      if (this.isChecked) {
        this.data.columns.forEach((item) => {
          item.enabled = true;
        });
        this.data.indeterminate = false;
      } else {
        this.data.columns.forEach((item) => {
          item.enabled = false;
        });
      }
    },
    indeterminateEnabledColumns() {
      let enabled = [];
      for (let item of this.data.columns) {
        if (item.enabled) {
          enabled.push(item);
        }
      }
      let isEnabled = this.data.columns.every((item) => item.enabled == true);

      if (isEnabled) {
        this.data.indeterminate = false;
        this.isChecked = true;
      } else {
        this.data.indeterminate = true;
        this.isChecked = false;
      }
      if (enabled.length == 0) {
        this.data.indeterminate = false;
      }
    },
  },
  directives: {
    indeterminate(el, binding) {
      el.indeterminate = binding.value;
    },
  },
};
</script>
