<template>
    <div class="mr-1 computer-control-wrapper" 
      :title="trans.tooltip.buttons.video"
    >
      <button
        class="toolbar-btn control-btn"
        
        type="button"
        @click="getStreamItems"
        v-click-outside="hideStreamVideoDropdown"
      >
      <!-- :class="{ disabled: !object.state_pst }" -->
        <i
          class="fas"
          :class="[
            streamVideoLoading
              ? 'fa-spinner fa-spin comp-loader'
              : 'fa-film comp-icon',
          ]"
        ></i>
        <i
          class="fas fa-caret-down ml-2"
          :class="{ 'rotate-180': showStreamVideoDropdown }"
        ></i>
      </button>
      <transition name="fadeDrop">
        <div class="dropdown-content shadow" v-if="showStreamVideoDropdown">
          <div class="content-row" 
            v-for="(item, index) in objectVideoLinks" :key="index"
            :class="{'p-0': item.type === 'delimiter'}"
            @click="item.type === 'video' || item.type === 'streamrequest'? goToStream(item): ''"
          >
            <i class="fas fa-video text-muted mr-1" v-if="item.type === 'streamrequest'"></i>
            <i class="fas fa-microphone text-muted" v-if="item.type === 'audio'"></i>

            <div class="delimiter" v-if="item.type === 'delimiter'"/>

            <a :href="item.url" target="_blank" v-if="item.type === 'link'">
              <i class="fas fa-link"></i>
              {{item.title}}
            </a>

            <a :href="item.src" v-if="item.type === 'streamrequest-old'">
              <i class="fas fa-film text-muted"></i>
              {{ item.title }}
            </a>
            <span>{{item.type !== 'link' && item.type !== 'streamrequest-old' ? item.title: ''}}</span>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
import api from "../../api";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../../bus";
import clickOutside from "vue-click-outside";
export default {
  props: {
    object: Object
  },
  data() {
    return {
      showStreamVideoDropdown: false,
      streamVideoLoading: false,
      objectVideoLinks: null
    }
  },
  computed: {
    ...mapGetters(["translations", "storeStream"]),
    trans() {
      return this.translations.vehicles;
    }
  },
  methods: {
    ...mapActions(['getStream']),
    async getStreamItems() {
      if (this.objectVideoLinks !== null) {
          this.showStreamVideoDropdown = !this.showStreamVideoDropdown
      } else {
        this.streamVideoLoading = true
        try {
        const response = await api.get(`/object/video/${this.object.vid}`)
        const data = response.data
        this.objectVideoLinks = data.items;
        this.showStreamVideoDropdown = true;
        
        this.streamVideoLoading = false
        } catch(e) {
          this.streamVideoLoading = false
          this.handleErrorMixin(e)
        }
      }
    },
    async goToStream(item) {
      if (item.type === 'streamrequest') {
        this.streamVideoLoading = true
        await api.post(item.data)
          .then(response => {
          }).then(async () => {
            await this.getStream()
            this.streamVideoLoading = false
            EventBus.$emit('show-videos-container')
          })
          .catch(e => {
            this.streamVideoLoading = false;
            this.handleErrorMixin(e)
          })

        return;
      }
    },
    hideStreamVideoDropdown() {
      this.showStreamVideoDropdown = false;
    }
  },
  directives: {
      clickOutside,
  },
}
</script>

<style>

</style>