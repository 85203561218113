<template>
  <div>
    <div
      v-for="(object, index) in objects"
      :key="object.vid"
      class="position-relative"
    >
      <div :class="'subview-' + (index + 1)" class="map-trace-subview">
        <div :id="'map-trace-' + (index + 1)"></div>
        <div class="trace-info-control-bar">
          <p class="map-streetview-name">{{ object.priority_name }}</p>
          <button class="btn btn-sm btn-light mr-2 tracking-btn" 
              :title="trans.track_on_main_map_button_title" @click="trackOnMainMap(object)">
            <i class="fas fa-level-up-alt rotate-90"></i>
          </button>
          <button class="btn btn-sm btn-light" @click="removeTrace(object)">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../bus";
import helper from '../../helper'

// TODO: need to review and refactor
export default {
  data() {
    return {
      mapOptions: {
        fullscreenControl: true,
        keyboardShortcuts: false,
        mapTypeControl: false,
        scrollwheel: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      },
      streetViewOptions: {
        addressControl: false,
        clickToGo: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      },
      objects: [],
      maxCount: 4,
    };
  },
  computed: {
    ...mapGetters(["map", "all", "translations"]),
    trans() {
      return this.translations.map
    }
  },
  created() {
    EventBus.$on("createTraceMap", (data) => {
      this.createTrace(data);
    });
    EventBus.$on("removeTraceMap", (data) => {
      this.removeTrace(data);
    });
  },
  methods: {
    ...mapActions(["createMarker", "panTo"]),
    createTrace(objectId) {
      if (this.objects.some((object) => object.vid == objectId)) {
        return false;
      }

      if (this.objects.length < this.maxCount) {
        let object = this.all.filter((object) => object.vid == objectId)[0];
        this.objects.push(object);

        this.mapOptions.center = {
          lat: parseFloat(object.lat),
          lng: parseFloat(object.lon),
        };
        this.mapOptions.zoom = this.map.mapTypes[this.map.mapTypeId].maxZoom;

        setTimeout(() => {
          const map = new google.maps.Map(
            document.getElementById("map-trace-" + this.objects.length),
            this.mapOptions
          );

          const mapTypes = helper.mapTypes()
          for (let mapType in mapTypes) {
            map.mapTypes.set(
              mapType,
              new google.maps.ImageMapType(mapTypes[mapType])
            );
          }
          const selectedMap = JSON.parse(localStorage.getItem('selectedMap'))
          const mapId =  selectedMap !== null ? selectedMap.id: 'roadmap'
          map.setMapTypeId(mapId);

          map.getStreetView().setOptions(this.streetViewOptions);

          this.createMarker({ object: object, map: map, trace: true });
        }, 100);
      }
    },
    removeTrace(object) { 
      // to remove class active from object control buttons
      this.objects = this.objects.filter((obj) => obj.vid !== object.vid);
      EventBus.$emit("traceClosed", object);
    },
    trackOnMainMap(object) {
      this.all.forEach(obj => obj.trackOnMainMap = false)
      const trackedObject = this.all.find(item => item.vid === object.vid);

      if (trackedObject.vid === object.vid) {
        trackedObject.trackOnMainMap = true;
        this.panTo(trackedObject);
        this.map.setZoom(14)
        this.removeTrace(object)
      }  
    },
  },
};
</script>