import Vue from "vue";
import Vuex from "vuex";
import apiLocal from "../apiLocal";
import api from "../api";
import user from "./modules/user";
import vehicle from "./modules/vehicle";
import map from "./modules/map";
import mapInfoBlock from "./modules/mapInfoBlock";
import EMYMarkers from "./modules/EMYMarkers";
import report from "./modules/report";
import useEvents from "@/store/modules/sse";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config: [],
        settings: [],
        locales: [],
        locale: "lv",
        translations: [],
        intervals: [],
        appSidebar: true,
        whoWasTherePeriod: [],
        APIConnectionError: false
    },
    actions: {
        setWhoWasChartPeriod(context, period) {
            context.commit('setWhoWasChartPeriod', period)
        },
        async getConfig(context) {
            await apiLocal.get("/config.json")
                .then((response) => {
                    context.commit("setConfig", response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getSettings(context) {
            await api.get("settings")
                .then((response) => {
                    context.commit("setSettings", response.data.data);
                })
                .then(() => {
                    context.commit(
                        "clusterState",
                        context.state.settings.sm_object_clustering_by_default
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getTranslations(context, data) {
            const language = data;
            await api.get("translations", {
                    params: {
                        lang: language,
                    },
                })
                .then((response) => {
                    context.commit("setTranslations", response.data.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addInterval(context, data) {
            context.commit("addInterval", data);
        },
        removeAllIntervals(context) {
            const intervals = context.getters.intervals;
            for (let i = 0; i < intervals.length; i++) {
                const interval = intervals[i];
                clearInterval(interval);
            }
            context.commit("removeAllIntervals");
        },
        toggleAppSidebar(context) {
            context.commit('toggleSidebar')
        },
        apiConnectionFailed({commit}) {
            commit('SET_API_CONNECTION_STATUS')
        },
    },
    mutations: {
        setWhoWasChartPeriod(state, period) {
            state.whoWasTherePeriod = period;
        },
        setSettings(state, data) {
            state.settings = data;
        },
        setTranslations(state, data) {
            state.translations = data;
        },
        addInterval(state, data) {
            state.intervals.push(data);
        },
        removeAllIntervals(state) {
            state.intervals = [];
        },
        toggleSidebar(state) {
            state.appSidebar = !state.appSidebar;
        },
        setConfig(state, data) {
            state.config = data;
        },
        SET_API_CONNECTION_STATUS(state) {
            state.APIConnectionError = true
        }
    },
    getters: {
        getWhoWasChartPeriod(state) {
            return state.whoWasTherePeriod
        },
        config(state) {
            return state.config;
        },
        settings(state) {
            return state.settings;
        },
        locale(state) {
            return state.locale;
        },
        translations(state) {
            return state.translations;
        },
        intervals(state) {
            return state.intervals;
        },
        showSidebar(state) {
            return state.appSidebar
        },
        isAPIConnectionFailed(state) {
            return state.APIConnectionError
        }
    },
    modules: {
        user,
        vehicle,
        map,
        mapInfoBlock,
        report,
        EMYMarkers,
        sse: useEvents(),
    },
});