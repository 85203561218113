<template>
    <div class="pip-container" 
        @mouseover="showBtn = true" 
        @mouseleave="showBtn = false"
    >
        <video :ref="pip.id" class="shadow"></video>
        <div 
            class="pip-controls" 
        >
            <span>{{ pip.title }}</span>
            <i class="far fa-share-square fa-sm return-to-original-icon"
                :class="{'show-back-btn': showBtn}"
                @click="goBackToOriginal(pip.id)"
            ></i>
        </div>
    </div>
</template>

<script>
    import Hls from 'hls.js'
    import { mapMutations } from 'vuex'
    import { EventBus } from '../../bus'
    export default {
        props: {
            pip: Object
        },
        data() {
            return {
                showBtn: false,
                hls: null
            }
        },
        mounted() {
            let video = this.$refs[this.pip.id]
            this.hls = new Hls();
            this.hls.loadSource(this.pip.src);
            this.hls.attachMedia(video);

            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                 video.play()
            })
        },
        methods: {
            ...mapMutations(['removeSinglePIP']),
            goBackToOriginal(id) {
                this.hls.destroy()
                this.hls.off(Hls.Events.MEDIA_ATTACHED);
                this.hls.off(Hls.Events.MANIFEST_PARSED);
                this.hls.off(Hls.Events.LEVEL_LOADED);
                this.hls.off(Hls.Events.LEVEL_UPDATED);
                this.hls.off(Hls.Events.ERROR);

                this.removeSinglePIP(id)
                EventBus.$emit('back-to-normal', this.pip.id)
            }
        }
    }
</script>

<style lang="scss" scoped>
.pip-container {
    position: relative;
    video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .pip-controls {
        position: absolute;
        top: 5px;
        right: 0;
        height: 45px;
        width: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 10px;

        span,
        .return-to-original-icon {
            background: rgba(0, 0, 0, 0.4);
        }
        span {
            padding: 3px 10px;
            border-radius: 5px;
            font-size: 14px;
        }
        .return-to-original-icon {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .2s;
            transform: scale(0);
            transform-origin: center;
            cursor: pointer;

            &.show-back-btn {
                transform: scale(1) !important;
            }
        }
    }
}
</style>
