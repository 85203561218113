<template>
  <div class="reset-pass-wrapper d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="card reset-pass-container pb-5 pb-lg-0 shadow">
            <div class="logo">
              <router-link :to="{name: 'objects'}">
                <img src="/img/smart_systems_logo.png" alt="Logo">
              </router-link>
            </div>
            <div class="card-header reset-pass-header">
              <h1 class="text-center">{{ trans.reset_pass }}</h1>
              <p class="text-center text-secondary small">
                {{ trans.enter_email_msg }}
              </p>
            </div>

            <div class="card-body reset-pass-content">
              <div class="form-group row">
                <div class="col">
                  <div class="user-email" :class="{'activated': emailFieldActive}">
                    <input
                        v-model="email"
                        @focus="emailFieldActive = true"
                        @blur="emailFieldActive = false"
                        class="form-control mb-3"
                        type="email" id="email"
                        name="email"
                        :placeholder="trans.type_email"
                        autofocus
                    >
                  </div>
                </div>
              </div>
              <transition name="slide-right">
                <p v-if="showMsg"
                    class="mb-0 small forget-pass-msg"
                    :class="[success ? 'text-success' : 'text-danger']">
                  {{message}}
                </p>
              </transition>
              <div class="form-group row mb-0">
                <div class="col d-flex justify-content-center">
                  <button type="submit"
                          @click="passwordResetLink"
                          class="btn reset-button minor-text w-75 mt-3"
                  >
                    <i class="fas mr-1" :class="[loading ? 'fa-sync-alt fa-spin' : 'fa-key']"></i>
                    <span>{{ trans.reset_btn_text }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      email: '',
      emailFieldActive: true,
      showMsg: false,
      message: '',
      success: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['translations']),
    trans() {
      return this.translations.auth;
    }
  },
  methods: {
    async passwordResetLink() {
      this.loading = true;
      await api.post('password/email', {
        email: this.email
      }).then(response => {
        const responseData = response.data;
        if (responseData.success) {
          this.message = responseData.data
          this.success = true;
          this.showMsg = true;
          this.loading = false;
        } else {
          this.success = false;
          this.message = responseData.data;
          this.showMsg = true;
          this.loading = false;
        }
      }).catch(err => {
        this.message = err.response.data.message;
        this.showMsg = true;
        this.loading = false;
        console.error(err.response.data);
      })
    }
  }
}
</script>
