import Swal from 'sweetalert2';
import { mapActions } from 'vuex'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    width: '25rem',
    timer: 10000,
    timerProgressBar: true,
    showCloseButton: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
function formatMessageWithParams(responseObj) {
    let { params, message } = responseObj

    params.reduce((acc, item) => {
        return message = message.replace(/%[a-z]/, item)
    }, message)

    return message
}
export default {
    methods: {
        ...mapActions(['setUser']),
        handleErrorMixin(err) {
            if(navigator.onLine) {
                let errorMsg = null
                const responseError = err.response
                
                if (err.message === 'Network Error') {
                    Toast.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: "Network error, please try again",
                        color: 'chocolate',
                        customClass: {
                            timerProgressBar: 'progress-warning',
                            popup: 'p-2'
                        },
                    })
                }
                const errorCode = responseError.status.toString()
                const responseMessage = responseError.data.message
    
                if (typeof responseMessage === 'object' && 'params' in responseMessage) {
                    errorMsg = formatMessageWithParams(responseMessage)
                }
    
                // 4xx error codes
                if (errorCode.startsWith('4')) {
                    errorMsg = responseMessage ? responseMessage : 'Error occurred, please try again'
                    Toast.fire({
                        icon: 'warning',
                        title: 'Warning',
                        text: errorMsg,
                        color: 'chocolate',
                        customClass: {
                            timerProgressBar: 'progress-warning',
                            popup: 'p-2'
                        },
                    })
                }
                
                // 5xx error codes
                if (errorCode.startsWith('5')) {
                    errorMsg = responseMessage ? responseMessage : 'Server error, please try again'
                    Toast.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorMsg,
                        color: 'crimson',
                        customClass: {
                            timerProgressBar: 'progress-error',
                            popup: 'p-2'
                        },
                    })
                }
            } else {
                this.setUser({ auth: false, token: null, user: {} });
                this.$router.push({name: 'login'})
            }
        }
    }
}