<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="transSideMenu.fuel_report.title"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-show="hasDataArrived"
      >
        <!-- action buttons -->
        <div class="report-action-buttons p-2 shadow">
          <div class="buttons">
            <button
              class="btn btn-light fullscreen-btn"
              :class="{ disabled: noDataFound }"
              @click="toggleReportFullscreen"
            >
              <i
                class="fas"
                :class="[
                  reportFullscreen
                    ? 'fa-compress-arrows-alt'
                    : 'fa-expand-arrows-alt',
                ]"
              ></i>
            </button>
            <button
              class="btn btn-light"
              :class="{ disabled: noDataFound }"
              @click="printReport"
            >
              <i class="fas fa-print text-success"></i>
            </button>
            <button
              class="btn btn-light"
              :class="{ disabled: noDataFound }"
              @click="downloadExcel"
            >
              <i
                class="fas text-muted"
                :class="[
                  excelDownloading
                    ? 'fa-spinner fa-spin loading'
                    : 'fa-file-excel',
                ]"
              >
              </i>
            </button>
          </div>
          <h2 class="report-name d-flex align-items-center ml-n5">
            <i :class="neededCriterias.icon"></i>
            {{ transSideMenu.fuel_report.title }}
          </h2>
          <button class="btn btn-light close-report" @click="closeReport">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <!-- report content -->
        <div class="report-content" ref="printReportContent">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{ transSideMenu.fuel_report.title + " " + todayDateForPrint }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">
              {{ transSideMenu.fuel_report.title }}
            </h4>
            <!-- report info -->
            <div class="report-info">
              <div>
                <p class="report-period">
                  {{ reportData.period.from + " - " + reportData.period.till }}
                </p>
              </div>
            </div>
            <div class="table-container">
              <!--Table-->
              <table class="reports-table shadow-sm">
                <thead>
                  <tr>
                    <th rowspan="2">Nr.p.k</th>
                    <th colspan="9">Transportlīdzeklis</th>
                    <th colspan="2">Nobraukums</th>
                    <th colspan="5">Degvielas patēriņš</th>
                    <th colspan="8">Degvielas daudzums (litros)</th>

                    <th rowspan="2">Degvielas tvertnes tilpums</th>

                    <th colspan="4">Degvielas ziņojumi</th>
                  </tr>
                  <tr>
                    <th>Īpašumā (""B"") vai turējumā (""L"")</th>
                    <th>Inventāra numurs</th>
                    <th>Marka, modelis</th>
                    <th>VRN</th>
                    <th>Izlaiduma gads</th>
                    <th>Ekspluatācijas grupa:(O)peratīvā, (S)tandarta, (OBK) Operatīvā Bez Krāsojuma</th>
                    <th>"Degvielas marka: DD, E95, E98"</th>
                    <th>Struktūrvienības vai iestādes nosaukums, kurai TrL nodots lietošanā</th>
                    <th>Ekspluatācijas dienas</th>

                    <th>mēnesī (KM)</th>
                    <th>kopā (kopš ekspluatācijas sākuma) uz 2023.01.01.</th>

                    <th>Noteiktā degvielas patēriņa norma uz 100 km (L)</th>
                    <th>Faktiskā degvielas patēriņa norma uz 100 km (L) pēc RVIS datiem</th>
                    <th>Degvielas patēriņa norma stāvēšanas režīmā (litri/stundā)</th>
                    <th>Motorstundas (stundas) mēnesī</th>
                    <th>Ar pavēli atļautais degvielas daudzums mēnesī (litri)</th>

                    <th>atlikums uz mēneša sākumu</th>
                    <th>Iepildīta degviela</th>
                    <th>pārliešana</th>
                    <th>*Cits veids</th>
                    <th>patērēts stāvēšanas režīmā</th>
                    <th>faktiskais degvielas patēriņs</th>
                    <th> faktiski izlietots mēnesī</th>
                    <th>atlikums mēneša beigās</th>

                    <th>Papildus degviela</th>
                    <th>Degvielas pārdale</th>
                    <th>Ziņojumi</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(reportRow, index) in reportData.report" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ reportRow.owner_type }}</td>
                    <td>{{ reportRow.inventory_number }}</td>
                    <td>{{ reportRow.make_model }}</td>
                    <td>{{ reportRow.reg_no }}</td>
                    <td>{{ reportRow.registration_date }}</td>
                    <td>{{ reportRow.usage_group }}</td>
                    <td>{{ reportRow.fuel_type }}</td>
                    <td>{{ reportRow.customer_name }}</td>
                    <td>{{ reportRow.usage_days }}</td>

                    <td>{{ formatNum(reportRow.miles_per_month, 'kilometer') }}</td>
                    <td>{{ formatNum(reportRow.mileage, 'kilometer') }}</td>

                    <td>{{ formatNum(reportRow.fuel_consumption_expected, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_consumption_actual, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_consumption_idle, 'liter') }}</td>
                    <td>{{ reportRow.stop_duration }}</td>
                    <td>{{ formatNum(reportRow.monthly_fuel_limit, 'liter') }}</td>


                    <td>{{ formatNum(reportRow.fuel_level_at_month_start, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_refueled, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.overfuel, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.column_21, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_consumption_idle_total, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_consumption_driving_total, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_total, 'liter') }}</td>
                    <td>{{ formatNum(reportRow.fuel_level_at_month_end, 'liter') }}</td>

                    <td>{{ formatNum(reportRow.fuel_tank_capacity, 'liter') }}</td>

                    <td>{{ reportRow.column_27 }}</td>
                    <td>{{ reportRow.column_28 }}</td>
                    <td>{{ reportRow.column_29 }}</td>
                    <td>{{ reportRow.column_30 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- no data found -->
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ transTable.no_data_found }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ transTable.other_obj }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ transTable.back }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import helper from "../../helper";
import { EventBus } from "../../bus";
import { Printd } from "printd";
import { css } from "./print-report-css";
import api from "../../api";

export default {
  components: {
    ReportsCriterias,
  },
  data() {
    return {
      neededCriterias: {
        icon: "fa fa-gas-pump",
        period: true,
        objects: true,
        hideEmptyObj: true,
        defaultDateFormat: "YYYY-MM-DD HH:mm:ss",
      },

      collectedData: null,
      loading: false,
      excelDownloading: false,
      hasDataArrived: false,
      reportData: [],
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "translations", "user"]),
    transSideMenu() {
      return this.translations.reports.side_menu;
    },
    transTable() {
      return this.translations.reports.table;
    },
    noDataFound() {
      return this.reportData.length === 0;
    },
  },
  methods: {
    ...mapActions(["setReportPeriod", "setReportRoute"]),
    getData(criterias) {
      this.collectedData = criterias;
      this.loading = true;
      api
        .get("report/fuel-card-full-report", {
          params: {
            from: this.collectedData.periodFrom,
            till: this.collectedData.periodTill,
            objects: this.collectedData.vehiclesIds,
          },
        })
        .then((response) => {
          this.reportData = response.data;
          this.hasDataArrived = true;
          this.loading = false;
        });
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            till: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "fuel-card-full-report",
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.transSideMenu.fuel_report.title} 
          ${moment(this.collectedData.periodFrom, "YYYY-MM-DD HH:mm:ss").format(
            "YYYYMMDD HHmmss"
          )} - ${moment(
            this.collectedData.periodTill,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYYMMDD HHmmss")}.xlsx`;
          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error);
          this.excelDownloading = false;
          console.error(error);
        });
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    formatNum(value, unit) {
      return helper.formatNum(value, unit);
    },
  },
};
</script>

<style scoped lang="scss">
  table {
    tr {
      th {
        padding-inline: 10px !important;
        padding-block: 15px !important;
        width: 300px !important;
        white-space: normal !important;
        overflow-wrap: break-word !important;
      }
    }
  }
</style>
