<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="transSideMenu.sensor_status.title"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-show="hasDataArrived"
      >
        <!-- action buttons -->
        <div class="report-action-buttons p-2 shadow">
          <div class="buttons">
            <button
              class="btn btn-light fullscreen-btn"
              :class="{ disabled: noDataFound }"
              @click="toggleReportFullscreen"
            >
              <i
                class="fas"
                :class="[
                  reportFullscreen
                    ? 'fa-compress-arrows-alt'
                    : 'fa-expand-arrows-alt',
                ]"
              ></i>
            </button>
            <button
              class="btn btn-light"
              :class="{ disabled: noDataFound }"
              @click="printReport"
            >
              <i class="fas fa-print text-success"></i>
            </button>
            <button
              class="btn btn-light"
              :class="{ disabled: noDataFound }"
              @click="downloadExcel"
            >
              <i
                class="fas text-muted"
                :class="[
                  excelDownloading
                    ? 'fa-spinner fa-spin loading'
                    : 'fa-file-excel',
                ]"
              >
              </i>
            </button>
            <button
              class="btn btn-light"
              :class="{ disabled: noDataFound }"
              @click="downloadPdf"
            >
              <i
                class="fas text-danger"
                :class="[
                  pdfDownloading ? 'fa-spinner fa-spin loading' : 'fa-file-pdf',
                ]"
              >
              </i>
            </button>
          </div>
          <h2 class="report-name d-flex align-items-center ml-n5">
            <!-- <i :class="neededCriterias.icon"></i> -->
            <img :src="neededCriterias.img" alt="" />
            {{ transSideMenu.sensor_status.title }}
          </h2>
          <button class="btn btn-light close-report" @click="closeReport">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <!-- report content -->
        <div class="report-content" ref="printReportContent">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{
                  transSideMenu.sensor_status.title +
                  " " +
                  (reportDataObjects.length > 1
                    ? ", "
                    : reportDataObjects[0].name + ", ") +
                  todayDateForPrint
                }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">
              {{ transSideMenu.sensor_status.title }}
            </h4>
            <!-- report info -->
            <div class="report-info">
              <div>
                <p class="company-name">{{ reportData.customer_name }}</p>
                <p class="report-period">{{ reportData.period }}</p>
              </div>
            </div>
            <div
              class="table-container"
              v-for="report in reportDataObjects[0].days"
              :key="report.date"
            >
              <!-- reg Num & date -->
              <div class="object-info">
                <p
                  v-for="(model, index) in reportDataObjects"
                  :key="index"
                  class="obj-name-model"
                >
                  {{ model.name }} -
                  <span class="model-make">{{ model.make_model }}</span>
                </p>
                <h5 class="report-date">{{ report.date_formatted }}</h5>
              </div>
              <table class="reports-table shadow-sm">
                <thead>
                  <tr>
                    <th>{{ transTable.event }}</th>
                    <th>{{ transTable.begin_time }}</th>
                    <th>{{ transTable.from }}</th>
                    <th>{{ transTable.end_time }}</th>
                    <th>{{ transTable.to }}</th>
                    <th>{{ transTable.duration }}</th>
                    <th>{{ transTable.average_speed }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in report.data"
                    :key="index"
                    @click="
                      goToHistoryChart(
                        report.date,
                        row.time_start,
                        row.time_end
                      )
                    "
                  >
                    <td
                      class="
                        text-left
                        font-weight-bold
                        text-capitalize text-muted
                      "
                    >
                      <img
                        :src="'/img/icons/lights.png'"
                        v-if="row.type == 'beacons'"
                      />
                      <img :src="'/img/icons/siren.png'" v-else />
                      <span class="beacon-siren">{{ row.type }}</span>
                    </td>
                    <td>{{ row.time_start }}</td>
                    <td>
                      <b v-if="row.location_start.geozone" class="d-block">{{
                        row.location_start.geozone.name
                      }}</b>
                      <span v-if="row.location_start.address">{{
                        row.location_start.address.address
                      }}</span>
                      <span v-else>{{ row.location_start }}</span>
                    </td>
                    <td>{{ row.time_end }}</td>
                    <td>
                      <b v-if="row.location_end.geozone" class="d-block">{{
                        row.location_end.geozone.name
                      }}</b>
                      <span v-if="row.location_end.address">{{
                        row.location_end.address.address
                      }}</span>
                      <span v-else>{{ row.location_end }}</span>
                    </td>
                    <td>{{ row.duration }}</td>
                    <td>{{ row.avg_speed }}</td>
                  </tr>
                  <tr class="tfoot">
                    <td colspan="4" class="text-left">
                      {{ transTable.number_of_rows }}:
                      <span>{{ report.total_count }}</span>
                    </td>
                    <td>{{ transTable.total }}:</td>
                    <td>
                      <span>{{ report.total_duration }}</span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- no data found -->
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ transTable.no_data_found }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ transTable.other_obj }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ transTable.back }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { EventBus } from "../../bus";
import { Printd } from "printd";
import { css } from "./print-report-css";
import api from "../../api";

export default {
  components: {
    ReportsCriterias,
  },
  data() {
    return {
      neededCriterias: {
        // icon: 'fas fa-lightbulb',
        img: "/img/icons/beacons.svg",
        period: true,
        object: true,
        hideEmptyObj: true,
      },

      collectedData: null,
      loading: false,
      excelDownloading: false,
      pdfDownloading: false,
      hasDataArrived: false,
      reportData: [],
      reportDataObjects: [],
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "translations"]),
    transSideMenu() {
      return this.translations.reports.side_menu;
    },
    transTable() {
      return this.translations.reports.table;
    },
    noDataFound() {
      return this.reportData.length == 0;
    },
  },
  methods: {
    ...mapActions(["setReportPeriod", "setReportRoute"]),

    goToHistoryChart(startDate, startTime, endTime) {
      let period = [
        moment(`${startDate} ${startTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        moment(`${startDate} ${endTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
      ];
      this.setReportPeriod(period);
      this.setReportRoute("sensor-status");
      this.$router.push({ name: "objects" }).catch(() => {});
      EventBus.$emit("openHistoryBlock", {
        vid: this.collectedData.vehiclesIds[0],
        priority_name: this.reportDataObjects[0].name,
      });
    },
    getData(criterias) {
      this.collectedData = criterias;
      this.loading = true;
      api
        .get("report/sensor-status", {
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
          },
        })
        .then((response) => {
          this.reportData = response.data.data;
          this.reportDataObjects = response.data.data.objects;
          this.hasDataArrived = true;
          this.loading = false;

          this.$audit({
            object_id: this.collectedData.vehiclesIds[0],
            action: "report_sensor_status",
            value: {
              from: this.collectedData.periodFrom,
              to: this.collectedData.periodTill,
            },
          });
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
        });
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "sensor-status",
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.reportDataObjects.length > 1 ? '' : this.reportDataObjects[0].name;
          link.download = `${this.transSideMenu.sensor_status.title} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.xlsx`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.excelDownloading = false;
          console.error(error);
        });
    },
    downloadPdf() {
      this.pdfDownloading = true;
      api
        .get("report/export-pdf", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "sensor-status",
          },
        })
        .then((response) => {
          this.pdfDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.reportDataObjects.length > 1 ? '' : this.reportDataObjects[0].name;
          link.download = `${this.transSideMenu.sensor_status.title} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.pdf`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.pdfDownloading = false;
          console.error(error);
        });
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
  },
};
</script>
