<template>
  <div>
    <div class="c-modal-overlay" @click="closeModal"></div>
    <div class="c-modal objects-modal">
      <!-- header -->
      <div class="c-modal-header shadow-sm">
        <div class="caption">
          <span class="flex-grow-1 pl-1 font-weight-bold small">
            <i class="fas fa-car mr-1"></i>
            {{ translations.reports.criterias.select }} 
            {{ singleMode ? translations.reports.criterias.object : translations.reports.criterias.objects }}
          </span>
          <i class="fas fa-times close-modal" @click="closeModal"></i>
        </div>
      </div>
      <!-- body -->
      <div class="c-modal-body">
        <!-- action section -->
        <div class="d-flex align-items-center justify-content-between">
          <!-- select all -->
          <div class="custom-control custom-checkbox" v-if="!singleMode">
            <input
              type="checkbox"
              class="custom-control-input"
              id="check-all"
              v-model="checkAll"
              @change="selectAll"
            />
            <label class="custom-control-label ml-1" for="check-all"></label>
          </div>
          <!-- sort -->
          <i
            class="toolbar-btn mr-1 fas"
            :class="toggleSortClasses"
            @click="sort"
          ></i>
          <!-- filter -->
          <div class="flex-grow-1 mr-1 modal-filter-wrapper">
            <input
              type="text"
              :placeholder="trans.objects_filter.find"
              ref="filterInput"
              v-model="filterText"
              @keyup="filter"
            />
            <i class="fa fa-filter filter-icon"></i>
          </div>
        </div>
        <!-- content section -->
        <div class="mt-2 p-2 border rounded flex-grow-1 overflow-auto">
          <div
            v-for="vehicle in filterdVehicles"
            :key="vehicle.vid"
            class="small d-flex align-items-center mb-2"
          >
            <input
              class="mr-1"
              type="checkbox"
              :class="{ 'vue-single-mode': singleMode }"
              :id="vehicle.vid"
              :value="vehicle.vid"
              v-model="checkedVehicles"
              @change="select"
            />
            <label :for="vehicle.vid" class="mb-0">{{
              vehicle.priority_name
            }}</label>
          </div>
          <i
            class="fas fa-spinner fa-spin ml-2 mt-2 text-primary"
            v-if="loading"
          ></i>
        </div>
      </div>
      <!-- objects count -->
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          small
          text-muted
        "
      >
        <span class="mr-1 small">{{ trans.objects }}:</span
        ><span class="small text-success">{{ filterdVehicles.length }}</span
        >, <span class="mx-1 small">{{ trans.checked }}:</span
        ><span class="small text-info">{{ checkedVehicles.length }}</span>
      </div>
      <!-- footer -->
      <div class="c-modal-footer bg-light mt-2 py-2 justify-content-between">
        <button class="btn btn-success w-75" @click="selectVehiclesBtn">
          <i class="fas fa-check mr-1"></i>{{ trans.ok }}
        </button>
        <button class="btn btn-light" @click="closeModal">
          <i class="fas fa-times mr-1 text-danger"></i>{{ trans.close }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import api from "../../api";

export default {
  props: {
    singleModeProp: Number,
  },
  data() {
    return {
      vehicles: [],
      filterText: "",
      checkAll: false,
      checkedVehicles: [],
      loading: false,
      sortByNameDesc: false,
      sortByNameAsc: true,

      singleMode: this.singleModeProp === 1,
    };
  },
  computed: {
    ...mapGetters(["modelObjects", "translations"]),
    trans() {
      return this.translations.vehicles
    },
    filterdVehicles() {
      const filtered = this.vehicles.filter((vehicle) => {
        return (
          vehicle.priority_name
            .toUpperCase()
            .indexOf(this.filterText.toUpperCase()) != -1
        );
      });
      return filtered;
    },
    toggleSortClasses() {
      return {
        "fa-sort-amount-down-alt": this.sortByNameDesc,
        "fa-sort-amount-down": this.sortByNameAsc,
      };
    },
  },
  async created() {
    await this.getObjects()
    
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.$emit("modalClosed");
      }
    });
  },
  mounted() {
    this.$refs.filterInput.focus();
  },
  methods: {
    async getObjects() {
      this.loading = true
      try {
        const response = await api.get("vehicles");
        this.loading = false
        this.vehicles = response.data.data
      } catch (e) {
        this.loading = false
        this.handleErrorMixin(e)
      }
    },
    closeModal() {
      this.$emit("modalClosed");
    },
    selectAll() {
      if (this.checkAll) {
        this.filterdVehicles.forEach((vehicle) => {
          this.checkedVehicles.push(vehicle.vid);
          let removeDublicated = new Set(this.checkedVehicles);
          this.checkedVehicles = [...removeDublicated];
        });
      } else {
        this.checkedVehicles = [];
      }
    },
    select() {
      this.checkAll = false;
      if (this.filterdVehicles.length === this.checkedVehicles.length) {
        this.checkAll = true;
      }
      // single mode
      if (this.singleMode) {
        if (this.checkedVehicles.length > 1) {
          this.checkedVehicles.splice(0, 1);
        }
        document.querySelectorAll(".vue-single-mode").forEach((checkbox) => {
          checkbox.checked = this.checkedVehicles.includes(checkbox.id);
        });
      }
    },
    sort() {
      this.sortByNameDesc = !this.sortByNameDesc;
      this.sortByNameAsc = !this.sortByNameAsc;
      this.filterdVehicles.sort((a, b) => {
        let nameA = a.priority_name.toUpperCase();
        let nameB = b.priority_name.toUpperCase();
        let sortState =
          this.sortByNameAsc === false ? nameA > nameB : nameA < nameB;
        if (sortState) {
          return -1;
        }
        if (!sortState) {
          return 1;
        }
        return 0;
      });
    },
    filter() {
      this.filterdVehicles;
      if (!this.singleMode) {
        // main checkbox state
        if (
          this.checkedVehicles.length > 0 &&
          this.filterdVehicles.length < this.checkedVehicles.length
        ) {
          this.checkAll = true;
        } else {
          this.checkAll = false;
        }
      }
    },
    selectVehiclesBtn() {
      let selectedVehicles = this.vehicles.filter((vehicle) =>
        this.checkedVehicles.includes(vehicle.vid)
      );
      this.$emit("selectedVehicles", selectedVehicles);
      this.$emit("modalClosed");
    },
  },
};
</script>
