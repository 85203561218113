<template>
  <div
    v-if="infoBlock.show"
    class="object-card-on-map"
    :style="{ top: infoBlock.y + 'px', left: infoBlock.x + 'px' }"
  >
    <div class="objects-list shadow noselect">
      <div class="objects-item-wrapper">
        <div class="objects-list-item">
          <div class="objects-card-header">
            <object-info-header
              :object="object"
              :trans="trans"
            ></object-info-header>

            <i
              class="fas fa-times card-close"
              @click="showInfoBlock(false)"
            ></i>
          </div>
        </div>
        <object-info-body :object="object" :trans="trans"></object-info-body>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectInfoHeader from "../object/ObjectInfoHeader.vue";
import ObjectInfoBody from "../object/ObjectInfoBody.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ObjectInfoHeader,
    ObjectInfoBody,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "filteredVehicles",
      "all",
      "translations",
      "infoBlock",
      "objectId",
    ]),
    object() {
      let objects = this.filteredVehicles || this.all;
      return objects.filter((object) => object.vid === this.objectId)[0];
    },
    trans() {
      return this.translations.vehicles;
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(["showInfoBlock"]),
  },
};
</script>
