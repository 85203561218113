export default {
    state: {
        infoBlock: {
            show: false,
            height: 0,
            width: 0,
            x: 0,
            y: 0,
        },
        objectId: null,
    },
    actions: {
        async showInfoBlock(context, data) {
            context.commit("setObjectId", data.id);
            let x = 0;
            let y = 0;
            if (data.show) {
                let marker = context.getters.markers[data.id];
                // TODO: Need to check on overlay duplicate creation
                let overlay = new google.maps.OverlayView();
                overlay.draw = function() {};
                overlay.setMap(context.getters.map);
                let position = overlay
                    .getProjection()
                    .fromLatLngToContainerPixel(marker.getPosition());
                x = position.x;
                y = position.y;

                if (x < 0) {
                    x = 0;
                }
                if (y < 0) {
                    y = 0;
                }
                const infoBlockWidth = 320;
                if (x + infoBlockWidth > data.containerWidth) {
                    x = data.containerWidth - infoBlockWidth;
                }
                const infoBlockHeight = 130;
                if (y + infoBlockHeight > data.containerHeight) {
                    y = data.containerHeight - infoBlockHeight;
                }
            }

            let infoBlock = {
                show: data.show,
                x: x,
                y: y,
            };

            context.commit("showInfoBlock", infoBlock);
        },
        async setSize(context, data) {
            context.commit("showInfoBlock", data);
        },
    },
    mutations: {
        showInfoBlock(state, data) {
            state.infoBlock = data;
        },
        setObjectId(state, data) {
            state.objectId = data;
        },
    },
    getters: {
        infoBlock(state) {
            return state.infoBlock;
        },
        objectId(state) {
            return state.objectId;
        },
    },
};