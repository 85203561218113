<template>
  <div class="bg-light buttons-wrapper">
    <!-- request position -->
    <button
      class="toolbar-btn mr-1 object-button-position"
      :title="trans.request_position"
      @click="requestPosition"
      v-if="buttonRequestPosition"
    >
      <p class="btn-response-notification" ref="positionMSG"></p>
      <i
        class="fas fa-crosshairs"
        :class="{
          'fa-spin': object.sendPosActive,
          'text-info': object.sendPosActive,
        }"
      ></i>
    </button>
    <!-- computer control -->
    <div class="mr-1 computer-control-wrapper" v-if="buttonComputerControl">
      <button
        class="toolbar-btn control-btn"
        type="button"
        :title="trans.tooltip.buttons.computer"
        @click="showComputerControlDropdown = !showComputerControlDropdown"
        v-click-outside="hideComputerControlDropdown"
      >
      <p class="btn-response-notification" ref="computerMSG"></p>
        <i
          class="fas"
          :class="[
            computerControlLoading
              ? 'fa-spinner fa-spin comp-loader'
              : 'fa-desktop comp-icon',
          ]"
        ></i>
        <i
          class="fas fa-caret-down ml-2"
          :class="{ 'rotate-180': showComputerControlDropdown }"
        ></i>
      </button>
      <transition name="fadeDrop">
        <div class="dropdown-content shadow" v-if="showComputerControlDropdown">
          <!-- start computer -->
          <div class="content-row" @click="startComputer">
            <i class="start-color fas fa-play"></i>
            <span>{{ trans.start_comp }}</span>
          </div>
          <!-- shutdown computer -->
          <div class="content-row" @click="shutdownComputer">
            <i class="shutdown-color fas fa-stop"></i>
            <span>{{ trans.shutdown_comp }}</span>
          </div>
          <!-- restore computer -->
          <div class="content-row" @click="restoreComputer">
            <i class="restore-color fas fa-undo"></i>
            <span>{{ trans.restore_comp }}</span>
          </div>
        </div>
      </transition>
    </div>
    <!-- Video stream -->
    <VideoDropdownButton :object="object" v-if="buttonVideo"/>
    <!-- <a :href="object.video_url" title="">
      <i
        class="fa fa-film btn-sm object-button-video mr-1 custom-btn"
        :class="{ disabled: !object.state_pst }"
        :title="trans.tooltip.buttons.video"
        v-if="buttonVideo"
      >
      </i>
    </a> -->
    <!-- map view -->
    <i
      class="fa fa-map-marked btn-sm object-button-trace mr-1 custom-btn"
      :class="{ active: object.traceButtonActive || (object.trackOnMainMap ? true: false) }"
      @click="toggleTrace"
      :title="trans.tooltip.buttons.trace"
    ></i>

    <!-- chart -->
    <i
      class="fa fa-chart-line btn-sm object-button-chart mr-1 custom-btn"
      :class="{ active: object.historyButtonActive }"
      @click="toggleHistoryBlock"
      :title="trans.tooltip.buttons.chart"
      v-if="buttonCharts"
    >
    </i>
    <!-- Trail -->
    <i
      class="fas fa-route object-button-tail btn-sm mr-1 custom-btn"
      :class="{ active: object.routeButtonActive }"
      @click="toggleRoute"
      :title="trans.tooltip.buttons.tail"
    >
    </i>
    <!-- active inactive control -->
    <i
      class="fas fa-eye object-button-tail btn-sm custom-btn"
      :class="[
        object.online ? 'fa-eye' : 'fa-eye-slash',
        { active: object.online },
      ]"
      @click="toggleOnline"
      :title="trans.tooltip.buttons.offline_online"
      v-if="buttonOfflineOnline"
    >
    </i>
  </div>
</template>

<script>
import api from "../../api";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../bus";
import clickOutside from "vue-click-outside";
import VideoDropdownButton from './VideoDropdownButton.vue';

export default {
  props: {
    object: Object
  },
  components: {
    VideoDropdownButton
  },
  data() {
    return {
      showComputerControlDropdown: false,
      computerControlLoading: false,
    };
  },
  computed: {
    ...mapGetters(["translations", "user", "all", "map"]),
    trans() {
      return this.translations.vehicles;
    },
    buttonRequestPosition() {
      return (
        this.user.permission &&
        this.user.permission["sm.objects.request_position"]
      );
    },
    buttonComputerControl() {
      return (
        this.object.pc &&
        this.user.permission &&
        this.user.permission["sm.objects.computer_control"]
      );
    },
    buttonVideo() {
      return (
        this.user.permission && this.user.permission["sm.objects.video_view"]
      );
    },
    buttonCharts() {
      return this.user.permission && this.user.permission["sm.objects.charts"];
    },
    buttonOfflineOnline() {
      return (
        this.user.permission &&
        this.user.permission["sm.objects.set_offline_online"]
      );
    },
  },
  created() {
    this.historyBlockClose();
  },
  mounted() {
    this.traceClosed();
  },
  methods: {
    ...mapActions([
      "createRoute",
      "removeRoute",
      "removeHistoryRoute",
      "removeRouteRunner",
      "resetReportPeriod",
      "panTo",
    ]),
    async traceClosed() {
      EventBus.$on("traceClosed", (object) => {
        if(this.object.vid === object.vid) {
          this.object.traceButtonActive = false
        } 
      });
    },
    async historyBlockClose() {
      EventBus.$on("historyBlockClose", () => {
        this.object.historyButtonActive = false;
        this.removeHistoryRoute();
        this.removeRouteRunner();
        // Remove event, duplicate call
        EventBus.$off("updateData"); // HistoryChart $emit/$on
        EventBus.$off("updateRoute"); // HistoryChart $emit / HistoryBlock $on
        EventBus.$off("resizeChart"); // ObjectSidebar $emit / HistoryChart $on
      });
    },
    async requestPosition() {
      const positionMsg = this.$refs.positionMSG
      this.object.sendPosActive = true;
      if (this.buttonRequestPosition) {
        await api
          .post("request-position", {
            object_id: this.object.vid,
            text: null,
          })
          .then(() => {
            this.object.sendPosActive = false;
            this.showNotification(positionMsg, 'success', this.trans.request_position_success)
            this.panTo({
              lat: this.object.lat,
              lon: this.object.lon,
              course: this.object.course,
            })
            this.$audit({
              object_id: this.object.vid,
              action: "object_request_position",
            });
          })
          .catch((error) => {
            console.log(error.message)
            this.object.sendPosActive = false;
            this.showNotification(positionMsg, 'error', this.trans.request_position_error)
            console.error(error);
          });
      }
    },
    toggleTrace() {
      this.object.traceButtonActive = !this.object.traceButtonActive;
      if (this.object.traceButtonActive && !this.object.trackOnMainMap) {
        EventBus.$emit("createTraceMap", this.object.vid);
        this.$audit({ object_id: this.object.vid, action: "object_tracking" });
      } else {
        if (this.object.trackOnMainMap) {
          this.object.trackOnMainMap = false
        }
        EventBus.$emit("removeTraceMap", this.object);
      }
    },
    toggleHistoryBlock() {
      this.resetReportPeriod();
      this.all.forEach((obj) => (obj.historyButtonActive = false)); //remove class active
      this.object.historyButtonActive = !this.object.historyButtonActive;
      if (!this.object.historyButtonActive) {
        EventBus.$emit("historyBlockClose");
        EventBus.$emit("close-history-block");
      } else {
        EventBus.$emit("openHistoryBlock", this.object);
      }
    },
    toggleRoute() {
      this.object.routeButtonActive = !this.object.routeButtonActive;
      if (this.object.routeButtonActive) {
        this.createRoute({
          objectId: this.object.vid,
          route: [
            {
              lat: parseFloat(this.object.lat),
              lng: parseFloat(this.object.lon),
            },
          ],
          update: true,
        });

        this.$audit({ object_id: this.object.vid, action: "object_trail" });
      } else {
        this.removeRoute({ objectId: this.object.vid });
      }
    },
    toggleOnline() {
      if (this.buttonOfflineOnline) {
        api
          .put("update-online", {
            object_id: this.object.vid,
          })
          .then((response) => {
            this.object.online = response.data.data;
            this.$audit({
              object_id: this.object.vid,
              action: "object_active_inactive",
            });
          })
          .catch((error) => {
            this.handleErrorMixin(error)
            console.error(error);
          });
      }
    },
    async startComputer() {
      const computerMsg = this.$refs.computerMSG
      if (this.buttonComputerControl) {
        this.computerControlLoading = true;
        await api
          .post("computer-control", {
            object_id: this.object.vid,
            action: "start",
          })
          .then(() => {
            this.computerControlLoading = false;
            this.showNotification(computerMsg, 'success', this.trans.request_position_success)
            this.$audit({
              object_id: this.object.vid,
              action: "object_active_inactive",
              value: "start",
            });
          })
          .catch((err) => {
            this.showNotification(computerMsg, 'error', this.trans.request_position_error)
            console.error(err);
            this.computerControlLoading = false;
          });
      }
    },
    async shutdownComputer() {
      const computerMsg = this.$refs.computerMSG
      if (this.buttonComputerControl) {
        this.computerControlLoading = true;
        await api
          .post("computer-control", {
            object_id: this.object.vid,
            action: "shutdown",
          })
          .then(() => {
            this.computerControlLoading = false;
            this.showNotification(computerMsg, 'success', this.trans.request_position_success)

            this.$audit({
              object_id: this.object.vid,
              action: "object_active_inactive",
              value: "shutdown",
            });
          })
          .catch((err) => {
            this.showNotification(computerMsg, 'error', this.trans.request_position_error)
            console.error(err);
            this.computerControlLoading = false;
          });
      }
    },
    async restoreComputer() {
      const computerMsg = this.$refs.computerMSG
      if (this.buttonComputerControl) {
        this.computerControlLoading = true;
        await api
          .post("computer-control", {
            object_id: this.object.vid,
            action: "restore",
          })
          .then(() => {
            this.computerControlLoading = false;
            this.showNotification(computerMsg, 'success', this.trans.request_position_success)
            this.$audit({
              object_id: this.object.vid,
              action: "object_active_inactive",
              value: "restore",
            });
          })
          .catch((err) => {
            this.showNotification(computerMsg, 'error', this.trans.request_position_error)
            console.error(err);
            this.computerControlLoading = false;
          });
      }
    },
    hideComputerControlDropdown() {
      this.showComputerControlDropdown = false;
    },
    showNotification(elementRef, status, transStatus) {
      elementRef.innerText = transStatus
      elementRef.classList.add(status, 'show')

      setTimeout(() => {
        elementRef.classList.remove('show', 'success', 'error')
      }, 5000)
    },
  },
  directives: {
    clickOutside,
  },
};
</script>