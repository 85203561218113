<template>
  <div class="c-modal-overlay" @click.self="closeModal">
    <div class="c-modal objects-by-customer-modal">
      <!-- header -->
      <div class="c-modal-header shadow-sm">
        <div class="caption">
          <span class="flex-grow-1 pl-1 text-left font-weight-bold">
            <span class="small font-weight-600">{{
              trans.objects_filter.title
            }}</span>
          </span>
          <i @click="closeModal" class="fas fa-times close-modal"></i>
        </div>
      </div>
      <!-- body -->
      <div class="c-modal-body">
        <!-- filter -->
        <div class="mb-2 modal-filter-wrapper">
          <input
            v-model="objectsFilterText"
            type="text"
            :placeholder="trans.objects_filter.find"
          />
          <i class="fa fa-filter filter-icon"></i>
        </div>
        <!-- select all -->
        <div class="my-1 filter-obj-modal-select-all">
          <input type="checkbox" id="check-all" v-model="checkAll" @change="handleCheckAll">
          <label for="check-all">{{ trans.objects_filter.check_all }}</label>
        </div>
        <!-- tree view -->
        <div class="border rounded flex-grow-1 overflow-auto">
          <tree
            ref="tree"
            :data="objectsTreeView"
            :filter="objectsFilterText"
            :options="treeOptions"
            v-if="objectsTreeView.length"
            @node:checked="setVisible"
            @node:unchecked="setHidden"
          >
          </tree>
          <i
            class="fas fa-spinner fa-spin ml-2 mt-2 text-primary"
            v-if="loading"
          ></i>
        </div>
      </div>
      <!-- objects checked count  -->
      <div class="mb-2 smallest text-center">
        {{trans.objects_filter.objects}}: 
        <span class="text-success mr-1">{{ objectsCount || 0}},</span> 
        {{trans.objects_filter.checked}}: 
        <span class="text-primary">{{ checkedCount || 0}}</span>
      </div>
      <!-- footer -->
      <div class="c-modal-footer bg-light py-2 justify-content-between">
        <button @click="filterObjects" class="btn btn-success w-75">
          <i v-if="filterObjLoading" class="fas fa-spinner fa-spin text-white"></i>
          <span v-else><i class="fas fa-check mr-1"></i>{{ trans.ok }}</span>
        </button>
        <button class="btn btn-light" @click="closeModal">
          <i class="fas fa-times mr-1 text-danger"></i>{{ trans.close }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import LiquorTree from "liquor-tree";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    isModalVisible: Boolean,
  },
  components: {
    [LiquorTree.name]: LiquorTree,
  },
  data() {
    return {
      isShown: this.isModalVisible,
      objectsFilterText: "",
      treeOptions: {
        checkbox: true,
        checkOnSelect: true,
        filter: {
          emptyText: ''
        }
      },
      objectIdsToShow: [],
      objectsTreeView: [],
      objectsCount: null,
      checkedCount: null,
      loading: false,
      checkAll: null,
      filterObjLoading: false
    };
  },
  created() {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.isShown = false;
        this.$emit("modalClosed", this.isShown);
      }
    });
    this.getObjectsByCustomer();
  },
  mounted() {
    this.treeOptions.filter.emptyText = this.trans.objects_filter.nothingfound
  },
  updated() {
    setTimeout(() => {
      if (this.objectsTreeView.length > 0) {
        this.checkAllCheckboxState()
      }
      this.checkedCount = this.$refs.tree.findAll({state: {checked: true}}).length
    }, 50)
  },
  computed: {
    ...mapGetters(["translations"]),
    trans() {
      return  this.translations.vehicles;
    },
  },
  methods: {
    ...mapActions(["getVehicles"]),
    handleCheckAll() {
      let unchecked = this.$refs.tree.findAll({state: {checked: false}})
      let checked = this.$refs.tree.findAll({state: {checked: true}})
      if (this.checkAll) {
        unchecked.check()
        checked.check()
      } else {
        checked.uncheck()
      }
    },
    async getObjectsByCustomer() {
      this.loading = true; 
      try {
        const response = await api.get("vehicles-customer");
        this.loading = false;
        this.objectIdsToShow = response.data.data
          .filter((row) => row.selected == 1)
          .map((item) => item.object_id);
        this.objectsTreeView = this.buildTreeView(response.data.data);  
        this.objectsCount = response.data.data.length
      } catch (e) {
        this.handleErrorMixin(e)
        console.log(e)
      }
    },
    setVisible(node) {
      if (node.data.object_id !== null) {
        this.objectIdsToShow.push(node.data.object_id);
      }
      this.checkAllCheckboxState()
      this.checkedCount = this.$refs.tree.findAll({state: {checked: true}}).length
    },
    setHidden(node) {
      if (node.data.object_id !== null) {
        this.objectIdsToShow = this.objectIdsToShow.filter(
          (id) => id !== node.data.object_id
        ); 
      }
      this.checkAllCheckboxState()
      this.checkedCount = this.$refs.tree.findAll({state: {checked: true}}).length
    },
    checkAllCheckboxState() {
      return this.checkAll = this.$refs.tree.findAll({state: {checked: false}}).length
        ? false: true
    },
    buildTreeView(items, id = null) {
      // If no parent but has parent id, set it as new parent
      items.forEach(element => {
        const hasParent = items.some((element2) => element.parent_id === element2.id);
        if (!hasParent) {
          element.parent_id = null;
        }
      });
      return items
        .filter((item) => item.parent_id === id)
        .map((item) => ({
          ...{
            id: item.id,
            parent_id: item.parent_id,
            data: {
              object_id: item.object_id || null,
            },
            text: item.text,
            state: { checked: item.selected === 1 ? true: false },
          },
          children: item.id ? this.buildTreeView(items, item.id) : [],
        }))
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        })
        .sort((a, b) => {
          return b.id - a.id;
        })
        .sort((a, b) => {
          if (a.id && b.id) {
            return a.text.localeCompare(b.text);
          }
        });
    },
    async filterObjects() {
      try {
        
        this.filterObjLoading = true
        await api.post("update-selected", {
          object_ids: this.objectIdsToShow,
        });
        await this.getVehicles()
        this.$emit("modalClosed");
      } catch (e) {
        this.handleErrorMixin(e)
        console.log(e.message)
        this.filterObjLoading = false
      }
    },
    closeModal() {
      this.$emit("modalClosed");
    },
  },
};
</script>
