import api from "../../api";
import { EventBus } from "../../bus";
import {SSE_MUTATION_SIGNAL} from "@/store/modules/sse";
// import testApi from "../../testApi"

export default {
    state: {
        firstLoad: true,
        vehicles: [],
        vehiclesLastData: [],
        searchWord: [],
        filteredVehicles: null,
        sortAsc: true,
        loading: true,

        streamIntervalTime: null,
        streams: [],
        streamPIP: [],
        pipX: 0,
        pipY: 0
    },
    actions: {
        async getVehicles(context) {
            let params = context.state.firstLoad ?  {with: 'location'} : '';
            try {
                const response = await api.get("objects", {params});
                context.commit("update", { objects: response.data.data });
                // Update info for filtered vehicles
                if (context.state.searchWord != "") {
                    context.commit("filter", {
                        search: context.state.searchWord,
                        markers: context.getters.markers,
                    });
                }

                if (response.data.data.length) {
                    context.state.firstLoad = false;
                }
                return response.data.data;
            } catch (e) {
                context.dispatch('killSession')  
            } finally {
                context.state.loading = false;
            }
        },
        async getVehicleLocations(context, ids) {
            try {
                const response = await api.post("vehicle-locations", {
                    ids: ids
                });

                context.commit("updateLocations", response.data.data);
            } catch (e) {
                console.error(e)
            }
        },
        filter(context, search) {
            context.commit("filter", {
                search: search,
                markers: context.getters.markers,
            });
        },
        sort(context) {
            context.commit("sort", []);
        },
        async getStream(context) {
            try {
                const response = await api.get('iamhere')
                const data = response.data
                if ('refresh' in data ) {
                    context.commit('setStreamInterval', data.refresh * 1000)
                } else {
                    context.commit('resetInterval')
                }
                if ('stream' in data) {
                    context.commit('setStream', data.stream)
                } else {
                    context.commit('resetStream')
                }
            } catch (e) {
                context.dispatch('killSession')  
                this.handleErrorMixin(e)
            }
        }
    },
    mutations: {
        [SSE_MUTATION_SIGNAL](state, {event, data}) {
            if (event === "map:car:data") {
                if (!state.firstLoad) {
                    this.commit('update', {objects: data});
                }
            }
        },
        update(state, data) {
            let objectIdsForLocationRequest = [];

            for (let vehicle of data.objects) {
                const vehicleLastData = state.vehiclesLastData[vehicle.vid];
                let stateVehicle = state.vehicles.find((stateVehicle) => {
                    if (stateVehicle.vid == vehicle.vid) {
                        return stateVehicle;
                    }
                });
                vehicle.showCard = false;
                vehicle.traceButtonActive = false;
                vehicle.trackOnMainMap = false;
                vehicle.routeButtonActive = false;
                vehicle.historyButtonActive = false;
                vehicle.sendPosActive = false;

                if (stateVehicle && stateVehicle.showCard) {
                    vehicle.showCard = true;
                }
                // Trace button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.traceButtonActive) {
                    vehicle.traceButtonActive = true;
                }
                if (stateVehicle && stateVehicle.trackOnMainMap) {
                    vehicle.trackOnMainMap = true;
                }
                // Route button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.routeButtonActive) {
                    vehicle.routeButtonActive = true;
                }
                // History button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.historyButtonActive) {
                    vehicle.historyButtonActive = true;
                }

                if (vehicle.temperature0 && stateVehicle) {
                    vehicle.temperature0 = stateVehicle.temperature0;
                }
                if (vehicle.temperature && stateVehicle) {
                    vehicle.temperature = stateVehicle.temperature;
                }
                if (vehicle.seal && stateVehicle) {
                    vehicle.seal = stateVehicle.seal;
                }

                if ((!vehicle.location) && stateVehicle) {
                    vehicle.location = stateVehicle.location;
                }
                if (vehicleLastData) {
                    if (vehicleLastData["temp0"]) {
                        vehicle.temperature0 = vehicleLastData["temp0"];
                    }
                    for (let tempKey in vehicleLastData["temp"]) {
                        vehicle["temperature"][tempKey] = vehicleLastData["temp"][tempKey];
                    }
                    for (let sealKey in vehicleLastData["seal"]) {
                        vehicle["seal"][sealKey] = vehicleLastData["seal"][sealKey];
                    }
                }

                // Trigger popup for SOS Alarm
                const haveSosAlarm = localStorage.getItem('alarm_' + vehicle.vid);
                if (vehicle.sos_alarm === true && haveSosAlarm != vehicle.txdt) {
                    EventBus.$emit("showAlarm", vehicle);
                }

                // add marker to the map
                // if first load after login/refresh or object not saved in store state
                if (state.firstLoad || !stateVehicle) {
                    this.dispatch("createMarker", {object: vehicle});
                } else {
                    this.dispatch("updateMarker", vehicle);
                    this.dispatch("updateRoute", vehicle);
                    this.dispatch("updateCluster");
                }

                if (stateVehicle && (stateVehicle.lat !== vehicle.lat || stateVehicle.lon !== vehicle.lon)) {
                    objectIdsForLocationRequest.push(vehicle.vid)
                }
                
                // track object on main map
                if (vehicle.trackOnMainMap) {
                    this.dispatch('panTo', vehicle)
                }

            }

            if (!state.firstLoad && state.vehicles.length !== data.objects.length) {
                const difference = state.vehicles.filter(
                    ({ vid: id1 }) => !data.objects.some(({ vid: id2 }) => id2 === id1)
                );
                this.dispatch("removeMarkers", difference);
                this.dispatch("removeCluster");
                this.dispatch("addCluster", data.objects);
            }

            if (state.firstLoad) {
                this.dispatch("createCluster");
            }

            // TODO: find best practice
            this.commit("sort", data.objects);
            state.vehicles = data.objects;

            // this.dispatch("getVehicleLocations", objectIdsForLocationRequest);
        },
        updateLocations(state, data) {
            for (let objectId in data) {
                let object = state.vehicles.find((stateVehicle) => {
                    if (stateVehicle.vid == objectId) {
                        return stateVehicle;
                    }
                });

                if (object) {
                    object.location = data[objectId];
                }
            }
        },
        filter(state, params) {
            const settings = this.state.settings;
            const oldSearch = state.searchWord;
            let search = (state.searchWord = params.search);

            let objectsArray = [];
            if (!search || search === {} || search == "") {
                state.filteredVehicles = null;
                this.dispatch("addMarkers", state.vehicles);
                this.dispatch("addCluster", state.vehicles);
            } else {
                let data;
                let multiSearch = search.split(" ");
                for (let search of multiSearch) {
                    if (search !== "") {
                        let word = search.toString().toUpperCase();
                        data = state.vehicles.filter((object) => {
                            if (settings.sm_object_filter_by == 1) {
                                return object.reg_no.toUpperCase().includes(word)
                            } else if (settings.sm_object_filter_by == 2) {
                                return object.name.toUpperCase().includes(word)
                            } else {
                                return object.priority_name.toUpperCase().includes(word)
                            }
                        });
                        if (data.length) {
                            objectsArray = new Set([...objectsArray, ...data]);
                        }
                    }
                }

                let objects = Array.from(objectsArray);
                // TODO: need to fix online/offline bug
                if (oldSearch !== params.search) {
                    let markers = state.vehicles.filter((object) =>
                        objects.every((objectFilter) => object.vid !== objectFilter.vid)
                    );
                    this.dispatch("removeMarkers", markers);
                    this.dispatch("addMarkers", objects);
                    this.dispatch("removeCluster");
                    this.dispatch("addCluster", objects);
                }

                // TODO: find best practice
                this.commit("sort", objects);

                state.filteredVehicles = objects;
            }
        },
        sort(state, vehicles) {
            let sortVehicles = !vehicles.length ?
                state.filteredVehicles || state.vehicles :
                vehicles;

            if (!vehicles.length) {
                state.sortAsc = !state.sortAsc;
            }

            sortVehicles.sort((a, b) => {
                let nameA = a.priority_name.toUpperCase();
                let nameB = b.priority_name.toUpperCase();
                let sortState = state.sortAsc === false ? nameA > nameB : nameA < nameB;
                if (sortState) {
                    return -1;
                }
                if (!sortState) {
                    return 1;
                }
                return 0;
            });
        },
        setStreamInterval(state, data) {
            state.streamIntervalTime = data
        },
        resetInterval(state) {
            state.streamIntervalTime = null
        },
        setStream(state, stream) {
            state.streams =  Array.from(new Set([...stream]))
        },
        resetStream(state) {
            state.streams = []
        },
        removeStream(state, id) {
            state.streams = state.streams.filter(item => item.id !== id)
        },
        setCustomPIP(state, pip) {
            pip.x = state.pipX
            pip.y = state.pipY
            state.streamPIP.push(pip)
            state.pipX += 100
            state.pipY += 100
        },
        removeSinglePIP(state, id) {
            state.streamPIP = state.streamPIP.filter(item => item.id !== id)
        },
    },
    getters: {
        all(state) {
            return state.vehicles;
        },
        filteredVehicles(state) {
            return state.filteredVehicles;
        },
        visible(state) {
            return state.filteredVehicles || state.vehicles;
        },
        loadingStatus(state) {
            return state.loading;
        },
        getStreamIntervalTime(state) {
            return state.streamIntervalTime
        },
        storeStream(state) {
            return state.streams
        },
        streamPIP(state) {
            return  Array.from(new Set([...state.streamPIP]))   
        }
    },
};