export default {
    methods: {
        handleControlsOnFullscreen(e) {
            if (!document.fullscreenElement && 
                !document.webkitIsFullScreen && 
                !document.mozFullScreen && 
                !document.msFullscreenElement) {
                this.isFullscreen = false
            } else {
                this.isFullscreen = true
            }
        },
        updateVolume() {
            if (this.video.muted) {
                this.video.muted = false;
            }
            this.video.volume = this.volume;
        },
        updateVolumeIcon() {
            return {
                'fa-volume-mute': this.video.muted || this.video.volume === 0,
                'fa-volume-down': this.video.volume > 0 && this.video.volume <= 0.5,
                'fa-volume-up'  : this.video.volume !== 0 && this.video.volume > 0.5,
                'mr-2': this.isFullscreen
            }
        },
        toggleMute() {
            this.video.muted = !this.video.muted;

            if (this.video.muted) {
                this.video.setAttribute('data-volume', this.volume);
                this.volume = 0;
            } 
            else {
                this.volume = this.video.dataset.volume
            }
            
            this.video.volume = this.volume;
        },
        toggleFullScreen() {
            const videoContainer = this.$refs[this.containerRef]
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else if (document.webkitFullscreenElement) {
                document.webkitExitFullscreen()
            } else if (videoContainer.webkitRequestFullscreen) {
                videoContainer.webkitRequestFullscreen()
            } else {
                videoContainer.requestFullscreen()
            }
        },
        hideResolutions() {
            this.showResolution = false
        },
    }
}