<template>
  <div>
    <div v-if="transLength">
      <app-navbar v-if="$route.meta.authorize"></app-navbar>
        <vue-draggable-resizable 
          v-for="one in streamPIP" :key="one.id"
          :w="400" 
          :h="225" 
          :min-width="400"
          :min-height="255"
          class-name="custom-drag" 
          :x="one.x"
          :y="one.y"
          :z="9999"
          :enable-native-drag="true"
          :handles="['ml','mr']"
        > 
          <PIP :pip="one"></PIP>
        </vue-draggable-resizable>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
        <warning-alarm
            v-if="showAlarm"
            :alarm-data="alarmInfoData"
            @modalClosed="showAlarm = !showAlarm"
        ></warning-alarm>
    </div>
    <NoConnection 
      v-if="isAPIConnectionFailed" 
      :retryLoading="APIRetryConnection"
      msg="API connection failed" 
      @retry="retry"
    />
    <NoConnection 
      v-if="isSessionKilled" 
      :login="true"
      msg="Session is expired" 
    />
  </div>
</template>

<script>
 import VueDraggableResizable from 'vue-draggable-resizable'
 import PIP from './components/object/PIP.vue'
import AppNavbar from "./layouts/AppNavbar.vue";
import WarningAlarm from "./components/global/WarningAlarm.vue";
import {mapActions, mapGetters} from "vuex";
import {EventBus} from "./bus";
import NoConnection from './components/global/NoConnection.vue'
import {eventsConfigure, eventsSubscribe, EventSubscriptions} from "@/store/modules/sse";
export default {
  components: {
    AppNavbar,
    WarningAlarm,
    NoConnection,
    VueDraggableResizable,
    PIP
  },
  data() {
    return {
      showAlarm: false,
      alarmInfoData: {},
      APIRetryConnection: false,
      streamInterval: null
    };
  },
  computed: {
    ...mapGetters([
      "config", 
      "settings", 
      "translations", 
      "isAPIConnectionFailed",
      "isSessionKilled", 
      "streamPIP",
      "getStreamIntervalTime",
      "map"
    ]),
    trans() {
      return this.translations;
    },
    transLength() {
      return this.trans && Object.keys(this.trans).length;
    },
  },

  async created() {
    const lang = localStorage.getItem("selectedLanguage");
    const user = JSON.parse(localStorage.getItem('user'));

    await this.getConfig();
    await this.getSettings();

    if (user && user.auth) {
      this.setUser(user)
      await this.checkUser()
      // this.startSSE_whenLoginOrEnterPage();
      this.handleObjectsIfNoSSE()

      await this.getStream()
    }

    await this.getTranslations(lang || this.settings.bo_language_default);

    this.showAlarmModal();

    // start run iamHere but not in login page
    this.unwatch = this.$watch('$route.name', (newVal) => {
      if(newVal !== 'login') {
        // this.startSSE_whenLoginOrEnterPage();
        this.handleObjectsIfNoSSE()
        this.getStream()
        this.unwatch()
      }
    })
    
  },
  mounted() {
    window.addEventListener('offline', (e) => {
      this.setUser({ auth: false, token: null, user: {} });
      this.$router.push({name: 'login'})
      })
  },
  watch: {
    getStreamIntervalTime(newValue, oldValue) {
      if (newValue !== null) {
        this.streamInterval = setInterval(async () => {
          await this.getStream()
        },  this.getStreamIntervalTime)
      } else {
        clearInterval(this.streamInterval)
      }
    }
  },
  methods: {
    ...mapActions([
      "getConfig", 
      "getSettings", 
      "getTranslations", 
      "setUser", 
      "checkUser",
      "getStream",
      "getVehicles",
      "addInterval"
    ]),
    showAlarmModal() {
      EventBus.$on("showAlarm", data => {
        this.alarmInfoData = data;
        this.showAlarm = true;
      });
    },
    retry() {
      this.APIRetryConnection = true
      setTimeout(() => {
        location.reload()
      }, 10000)
    },
    startSSE_whenLoginOrEnterPage() {
      if (!this.subscriptions) {
        // General SSE configuration
        const eventsURL = this.$store.getters.config.APP_API_URL + 'sse'
        const userToken = this.$store.getters.accessToken || null;

        this.subscriptions = new EventSubscriptions();
        this.subscriptions.subscribe('ObjectsMapLastData', ["map:car:data"])

        eventsConfigure(this.$store.dispatch, eventsURL, userToken);
        eventsSubscribe(this.$store.dispatch, this.subscriptions);
      }
    },
    handleObjectsIfNoSSE() {
      if ('sse_enabled' in this.settings && this.settings.sse_enabled) {
        this.watchMapAndLoadObjects()
        this.startSSE_whenLoginOrEnterPage();
      } else {
        this.watchMapAndLoadObjects()
        const objectsInterval = setInterval(() => {
          this.getVehicles();
        }, 5000);

        this.addInterval(objectsInterval)
      }
    },
    watchMapAndLoadObjects() {
      this.unwatchMap = this.$watch('map', async (newVal) => {
        if (newVal !== null) {
          await this.getVehicles();
          this.unwatchMap()
        }
      }) 
    }
  },
};
</script>