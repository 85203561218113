<template>
  <div
    id="objects-sidebar"
    class="page-objects-sidebar bg-white"
    :class="{ 'no-sidebar': !showSidebar }"
  >
    <button class="btn btn-sm d-flex menu-trigger" @click="toggleSidebar">
      <i class="fas fa-exchange-alt"></i>
    </button>
    <div class="objects-list-wrapper">
      <object-toolbar></object-toolbar>
      <div class="objects-list shadow noselect" ref="objectsList">
        <div
          v-for="object in objectsList"
          :key="object.vid"
          class="objects-item-wrapper"
        >
          <!-- object list item -->
          <div
            class="objects-list-item"
            :class="{ 'is-closed': !object.showCard }"
          >
            <div class="objects-card-header">
              <!-- object card header -->
              <object-info-header :object="object"></object-info-header>
              <!-- dropdown arrow -->
              <div
                class="show-obj-info-button"
                @click="toggleAccordion(object, $event)"
              >
                <i
                  class="fas fa-chevron-down arrow-down"
                  :class="{ show: object.showCard }"
                ></i>
              </div>
            </div>
          </div>
          <!-- object card content -->
          <object-info-body
            :object="object"
            v-show="object.showCard"
          ></object-info-body>
        </div>
        <!-- / v-for -->
        <div class="objects-loading" v-if="loading">
          <i class="fas fa-spinner fa-spin mr-2 text-primary"></i>
          <span class="small font-italic font-weight-bold text-muted">{{
            trans.loading
          }}</span>
        </div>
      </div>
      <!-- / objects-list container -->

      <!-- No objects -->
      <div
        class="no-filtered-objects"
        v-if="!objectsList.length && loadingStatus == false"
      >
        <button class="btn btn-outline-dark" @click="openObjectFilter">
          <i class="fas fa-filter"></i>
          {{ trans.objects }}
        </button>
        {{ trans.select_objects }}
      </div>
    </div>
  </div>
</template>

<script>
import ObjectToolbar from "./ObjectToolbar.vue";
import ObjectInfoHeader from "./ObjectInfoHeader.vue";
import ObjectInfoBody from "./ObjectInfoBody.vue";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../bus";
import api from "@/api";
import EMYToastCustom from "../global/EMYToastCustom.vue";
export default {
  components: {
    ObjectToolbar,
    ObjectInfoHeader,
    ObjectInfoBody,
    EMYToastCustom
  },
  data() {
    return {
      EMYMarkers: {},
      EMYInfoWindow: {}
    };
  },
  computed: {
    ...mapGetters([
      "settings",
      "filteredVehicles",
      "all",
      "loadingStatus",
      "translations",
      "user",
      "showSidebar",
      "map",
    ]),
    trans() {
      return this.translations.vehicles;
    },
    objectsList() {
      return this.filteredVehicles || this.all;
    },
    loading() {
      return this.loadingStatus;
    },
    objectsIds() {
      const objects = this.filteredVehicles || this.all;
      return objects.map((object) => object.vid);
    },
    isEMYNotificationSingle() {
      return this.settings.sm_notification_dialog === 'single'
    }
  },
  async created() {
    /* make sure the map is loaded before the objects
       to prevent objects not showing on map
    */
    // this.unwatchMap = this.$watch('map', async (newVal) => {
    //   if (newVal !== null) {
    //     await this.getVehicles();
    //     this.unwatchMap()
    //   }
    // })

    // const objectsInterval = setInterval(() => {
    //   this.getVehicles();
    // }, 5000); // Every 5sec
    //
    if (this.settings.is_emy) {
      setInterval(async () => {
        await this.getMapPointFromEmy();
      }, 3000); // Every 1sec
    }
    //
    // this.addInterval(objectsInterval);
    // destroy WhoWasThere rectangle if it was active
    EventBus.$emit("destroyShape");
  },
  mounted() {
    /* emitted from MapToolbar when switch on share
      location button to remove EMYInfoWindow and marker if any
    */
    EventBus.$on("close-EMY-infoWindow", () => {
       if (Object.keys(this.EMYMarkers).length !== 0) {
        this.EMYRemoveMarkerAndInfoWindow();
      }
    });
  },
  methods: {
    ...mapActions([
      "addInterval",
      "getVehicles",
      "toggleAppSidebar",
      "createAddressMarker",
      "panTo",
      "killSession",
      "createEMYSingleMarker",
      "removeEMYSingleMarker",
      "createEMYMarker",
      "removeEMYMarker"
    ]),
    toggleSidebar() {
      this.toggleAppSidebar();
      EventBus.$emit("resizeChart");
    },
    toggleAccordion(object, e) {
      object.showCard = !object.showCard;
      let element = e.target;
      let topPos = element.getBoundingClientRect().top + window.scrollY;
      let objectListContainer = this.$refs.objectsList;

      if (topPos > objectListContainer.offsetHeight - 20) {
        setTimeout(() => {
          objectListContainer.scrollBy({
            top: element.closest(".objects-item-wrapper").clientHeight,
            behavior: "smooth",
          });
        }, 200);
      }
      this.$audit({ object_id: object.vid, action: "object_card_toggle" });
    },
    async getMapPointFromEmy() {
      try {
        const response = await api.get("maps/emy-gps-data");
        let data = response.data.data

        if (data) {
          const latLon = { lat: data.lat, lng: data.lon };
          if (this.isEMYNotificationSingle) {
            let singleMarker = this.$store.state.EMYMarkers.EMYSingleMarker

            if (Object.keys(singleMarker).length > 0) {
              let oldSingleMarkerID = Object.keys(singleMarker)[0]
              this.$toast.dismiss(oldSingleMarkerID)
              this.createEMYSingleMarker({data, map: this.map})
              this.EMYToastMessages(data)
              this.EMYGoToPoint(latLon)
              return;
            }
            this.createEMYSingleMarker({data, map: this.map})
          } else {
            this.createEMYMarker({data, map: this.map})
          }

          this.EMYToastMessages(data)
          this.EMYGoToPoint(latLon)
        }
      } catch (e) {
        this.handleErrorMixin(e)
        console.error(e);
      }
    },
    async EMYToastMessages(data) {
      if (data) {
        const content = {
          component: EMYToastCustom,
          props: {
            data,
            trans: this.translations
          },
          listeners: {
            goToPoint: (latLon, id) => {
              if (this.$route.name !== 'objects') {
                this.$router.push({ name: 'objects'})
              }
              this.EMYGoToPoint(latLon)
            }
          }
        }
        const toastOption = {
          position: 'top-right',
          icon: false,
          closeButton: false,
          timeout: false,
          closeOnClick: false,
          toastClassName: 'emy-custom-toast',
          id: data.requestId,
          onClose: () => {
            if (this.isEMYNotificationSingle) {
              this.removeEMYSingleMarker(data.requestId)
            } else {
              this.removeEMYMarker(data.requestId)
            }
          }
        }
        this.$toast(content, toastOption);
      }
    },
    async EMYGoToPoint(latLon, id) {
      this.map.setCenter(latLon)
      this.map.setZoom(14)
    },
    EMYRemoveMarkerAndInfoWindow() {
      this.EMYMarkers.setMap(null);
      this.EMYMarkers = {};
      this.EMYInfoWindow.close();
    },
    openObjectFilter() {
      EventBus.$emit("open-object-filter");
    },
  },
};
</script>
