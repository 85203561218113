export let css = `
body {
    -webkit-print-color-adjust: exact;
    font-family: 'Arial';
}
.text-left {
  text-align: left;
}
.d-block {
  display: block;
}
.font-weight-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize;
}

.pdf-header {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  color: #555;
}
.pdf-report-name {
  display: block;
  text-align: center;
  color: #555;
  font-size: 20px;
}
.pdf-signature {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  justify-content: flex-end;
}
.signature-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  font-size: 13px;
}
.signature-row span {
  margin-bottom: 20px;
}

.beacon-siren {
  position: relative;
  bottom: 5px;
}
.report-info {
    font-size: 15px;
    font-weight: bold;
    color: #555;  
    display: flex;
    justify-content: space-between;
  }
  .company-name {
    margin-bottom: 0;
  }
  .report-period {
    margin-bottom: 10px;
  }
  .report-date {
    font-size: .8rem;
    color: #626262;
  }
  .table-header-big-info {
    font-size: 15px;
    color: #555;
    font-weight: bold;
  }
  .table-header-big-info p {
    margin-bottom: 0;
  }
  .table-header-big-info .object-info-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: normal;
    margin: 25px 0;
  }
  .info-column {
    flex-grow: 1;
    margin-right: 10px;
  }
  .info-column p {
    margin-bottom: 5px;
    display: flex;             
  }
  .info-column p  span {
    width: 60%;
    text-align: right;
    margin-right: 1px;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
  }
  .info-column p strong {
    width: 40%;
    text-align: right;
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
  }
  .info-column p strong span:first-child {
    text-align: right;
    width: 65%;
    margin-right: 0;
    font-weight: bold;
  }
  .info-column p strong span:last-child {
    width: 35%;
    margin-right: 0;
    font-weight: bold;
    text-align: left;
    margin-left: 2px;
  }
  .object-info {
    font-size: .8rem;
    color: #626262;
  }
  .obj-name-model {
    display: inline-block;
    width: 40%;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 14px;
  }
  .model-make {
    color: #878787;
  }
  .report-date {
    display: inline;
  }
  .table-container {
    width: 100%;
  }
  .reports-table {
    border-collapse: collapse; 
    text-align: center; 
    width: 100%; 
    margin-bottom: 1.5rem;   
  }
  .weekend {
    background-color: #FCE6EA;
  }
  th, td {
    color: #555;
    border: 2px solid #ccc;
  }

  .reports-table thead tr {
    background: #efefef;
  }
  .reports-table thead tr th {
    font-size: 15px;
    padding: 4px;
    white-space: nowrap;
  }
  
  .fixed-width {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
  }

  .reports-table tbody tr td {
    font-size: 14px;
    padding: 5px;
    font-weight: 500;
  }
  .reports-table tbody tr img {
    width: 20px;
    margin-right: 6px;
  }
  .reports-table .tfoot {
    border: 2px solid #ccc;
  }
  .reports-table .tfoot td {
    border: 0;
    border-top: 2px solid #ccc;
    font-size: 13px;
    padding: 10px;
    font-weight: 500;
    white-space: nowrap; 
  }
  .reports-table .tfoot td:first-of-type {
    text-align: left;
  }
  .reports-table .tfoot td:nth-of-type(2) {
    text-align: right;
  }
  .reports-table .tfoot td span {
    font-weight: 700;
  }
  .reports-table.mileage-table  tbody tr td:last-child {
    font-weight: 650;
  }
  .reports-table.mileage-table .tfoot .total-row td:not(:first-of-type) {
    text-align: right;     
  }
  @media print {
    body {
        -webkit-print-color-adjust: exact;
    }
  }

`;