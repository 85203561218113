<template>
  <div>
    <div id="object-history-chart"></div>

    <div class="chart-mileage">
      <div class="mileage-info">
        <span class="mr-1">{{
          trans.history.chart.footer.mileage_gps_can
        }}</span>
        <span class="chart-gps text-muted">{{ distanceGps }}</span> /
        <span class="chart-can text-muted">{{ distanceOdometer }}</span>
      </div>
      <div class="chart-tooltip-info">
        <div class="chart-tooltip-date" v-show="hover.date">
          <span class="date">{{ hover.date }}</span>
        </div>
        <div class="chart-tooltip-location" v-show="hover.location">
          <span>{{ trans.history.chart.footer.location }}</span>
          <span class="text-muted location">{{ hover.location }}</span>
        </div>
        <div class="chart-tooltip-speed-gps" v-show="hover.speed">
          {{ trans.history.chart.footer.speed_gps }}
          <span class="strong mr-1 text-muted">{{ hover.speed }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import highcharts from "highcharts";
import boost from "highcharts/modules/boost";
import moment from "moment";
import helper from "../../helper";
import { EventBus } from "../../bus";
import { mapActions, mapGetters } from "vuex";

boost(highcharts);

export default {
  props: {
    historyResponseData: Array,
    chartPrimaryValue: String,
    chartSecondaryValue: String,
    object: Object,
  },
  data() {
    return {
      data: [],
      subData: [],
      hover: {
        date: null,
        location: null,
        speed: null,
      },
      chart: null,
      charts: {
        gps: {
          config: [
            {
              type: "area",
              title: "km/h",
              name: "Speed",
              color: "#4040ff",
              connectNulls: false,
              fillOpacity: 0.5,
              minValue: 0,
              minRange: 1,
              tickInterval: 10,
            },
          ],
          data: {
            count: 1,
            settings: [
              {
                rowProperty: "speed_gps",
                property: null,
                defaultValue: 0,
                counter: false,
              },
            ],
          },
        },
        "gps-rpm": {
          config: [
            {
              type: "area",
              title: "km/h",
              name: "Speed",
              color: "#4040ff",
              connectNulls: false,
              fillOpacity: 0.5,
              minValue: 0,
              tickInterval: 10,
            },
            {
              type: "spline",
              title: "Engine speed (1000/min)",
              name: "RPM",
              yAxis: 1,
              color: "#ff0000",
              connectNulls: false,
              fillOpacity: 0.25,
              minValue: 0,
              opposite: true,
            },
          ],
          data: {
            count: 2,
            settings: [
              {
                rowProperty: "speed_can",
                property: null,
                defaultValue: 0,
                counter: false,
              },
              {
                rowProperty: "can_rpm",
                property: null,
                defaultValue: 0,
                counter: false,
              },
            ],
          },
        },
        "fuel-level": {
          config: [
            {
              type: "spline",
              title: "L",
              name: "Fuel level (1st tank)",
              color: "#ff0000",
              connectNulls: true,
              maxValue: this.object.fuel_tank_1_capacity,
              minValue: 0,
              calculateTickInterval: true,
              tickAmount: 7,
            },
          ],
          data: {
            count: 1,
            settings: [
              {
                rowProperty: "can_fuel_level_1",
                property: null,
                defaultValue: null,
                counter: false,
              },
            ],
          },
        },
        temp: {
          config: [
            {
              type: "spline",
              title: "&deg;C",
              name: "Temp",
              connectNulls: true,
            },
          ],
          data: {
            count: 5,
            settings: [
              {
                rowProperty: "temp_",
                property: "temperature",
                defaultValue: null,
                counter: true,
              },
            ],
          },
        },
        seal: {
          config: [
            {
              type: "line",
              title: "SmartSeal",
              name: "Seal",
              color: "#ff0000",
              connectNulls: true,
            },
          ],
          data: {
            count: 4,
            settings: [
              {
                rowProperty: "seal_",
                property: "temperature",
                defaultValue: null,
                counter: true,
              },
            ],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["translations", "user"]),
    trans() {
      return this.translations;
    },
    distanceGps() {
      let drivenKm =
        this.historyResponseData[this.historyResponseData.length - 1].driven_km;
      if (helper.isSafari()) {
        return drivenKm.toFixed(3);
      } else {
        return new Intl.NumberFormat(this.user.language).format(drivenKm);
      }
    },
    distanceOdometer() {
      let odometerFirst = 0;
      for (let i = 0; i < this.historyResponseData.length; i++) {
        let odometer = this.historyResponseData[i].odometer;
        if (odometer) {
          odometerFirst = odometer;
          break;
        }
      }

      let distance =
        this.historyResponseData[this.historyResponseData.length - 1].odometer -
        odometerFirst;
      if (helper.isSafari()) {
        distance = distance.toFixed(3) + " km";
      } else {
        distance = new Intl.NumberFormat(this.user.language, {
          style: "unit",
          unit: "kilometer",
          minimumFractionDigits: 3,
        }).format(distance);
      }

      return distance;
    },
  },
  watch: {
    historyResponseData: {
      deep: true,
      immediate: true,
      handler() {
        this.updateChart();
      },
    },
    chartPrimaryValue: {
      deep: true,
      immediate: false,
      handler() {
        this.updateChart();
      },
    },
    chartSecondaryValue: {
      deep: true,
      immediate: false,
      handler() {
        this.updateChart();
      },
    },
  },
  created() {
    this.updateData();
    this.panToMap();
    this.resizeChart();
  },
  methods: {
    ...mapActions(["addRouteRunner", "updateRouteRunner", "panTo"]),

    async updateData() {
      EventBus.$on("updateData", (data) => {
        let speed = null;
        if (helper.isSafari()) {
          speed = data.speed + " km/h";
        } else {
          speed = new Intl.NumberFormat(this.user.language, {
            style: "unit",
            unit: "kilometer-per-hour",
          }).format(data.speed);
        }

        this.hover.date = moment(data.date).format("YYYY-MM-DD HH:mm:ss");
        this.hover.location = this.trans.history.no_data;
        if (data.lat != 0 && data.lon != 0) {
          this.hover.location = data.lat + " | " + data.lon;
          this.updateRouteRunner({ lat: data.lat, lon: data.lon });
        }
        this.hover.speed = speed;
      });
    },
    async panToMap() {
      EventBus.$on("panToMap", (data) => {
        this.panTo(data);
      });
    },
    async resizeChart() {
      EventBus.$on("resizeChart", () => {
        setTimeout(() => {
          this.chart.setSize(null);
        }, 300);
      });
    },
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.drawChart();
    },
    async drawChart() {
      await this.getData();
      const yAxis = this.getYAxis();
      const series = this.getSeries();

      this.chart = highcharts.chart("object-history-chart", {
        chart: {
          zoomType: "x",
          height: 260,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        time: {
          useUTC: false,
        },
        xAxis: {
          crosshair: {
            color: "#cccccc",
            width: 2,
          },
          events: {
            afterSetExtremes: function () {
              // HistoryBlock.vue -> updateTableData()
              EventBus.$emit("updateTableData", this.series[0].points);
              // HistoryBlock.vue -> updateRoute()
              EventBus.$emit("updateRoute", this.series[0].points);
              // HistoryBlock.vue -> Empty Excel marker while chart zoomed
              EventBus.$emit("chartZoomed");
            },
          },
          type: "datetime",
          gridLineWidth: 1,
          plotBands: this.subData,
        },
        yAxis: yAxis,
        legend: {
          layout: "horizontal",
          margin: 0,
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d %H:%M:%S",
          shared: true,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                click: function () {
                  EventBus.$emit("panToMap", {
                    lat: this.lat,
                    lon: this.lon,
                  });
                },
                mouseOver: function () {
                  EventBus.$emit("updateData", {
                    date: this.x,
                    lat: this.lat,
                    lon: this.lon,
                    speed: this.speed,
                  });
                },
              },
            },
            boostThreshold: 10000,
            turboThreshold: 0,
          },
        },
        series: series,
      });
    },
    async getData() {
      let data = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      };
      let subData = [];
      let subDataFrom = null;
      let subDataPreviousColor = null;
      let chart = this.charts[this.chartPrimaryValue];
      let chartDataSettings = chart.data.settings;

      for (let i = 0; i < this.historyResponseData.length; i++) {
        let row = this.historyResponseData[i];
        let timestamp = moment(row.tx_date + " " + row.tx_time).valueOf();

        for (let i = 0; i < chart.data.count; i++) {
          let chartDataSetting = chart.data.settings[0];
          if (chartDataSettings.length > 1) {
            chartDataSetting = chart.data.settings[i];
          }

          let value =
            row[chartDataSetting.rowProperty] || chartDataSetting.defaultValue;
          if (chartDataSetting.property) {
            value = row[chartDataSetting.rowProperty]
              ? parseFloat(
                  row[chartDataSetting.rowProperty][chartDataSetting.property]
                )
              : chartDataSetting.defaultValue;
          }
          if (chartDataSetting.counter) {
            value = row[chartDataSetting.rowProperty + i]
              ? parseFloat(row[chartDataSetting.rowProperty + i])
              : chartDataSetting.defaultValue;
          }
          if (chartDataSetting.property && chartDataSetting.counter) {
            value = row[chartDataSetting.rowProperty + i]
              ? parseFloat(
                  row[chartDataSetting.rowProperty + i][
                    chartDataSetting.property
                  ]
                )
              : chartDataSetting.defaultValue;
          }

          data[i].push({
            x: timestamp,
            y: value,
            lat: row.lat,
            lon: row.lon,
            speed: row.speed_gps,
          });
        }

        let subDataValue = null;
        let subDataColor = null;
        if (this.chartSecondaryValue === "engine") {
          subDataValue = row.engine;
          subDataColor = "#c0ffc0";
          // subDataColor = subDataValue ? row.status_color : '#ffffff';
        } else if (this.chartSecondaryValue === "ref") {
          subDataValue = row.beacons;
          subDataColor = "#c0c0ff";
          // subDataColor = subDataValue ? row.status_color : '#ffffff';
        } else if (this.chartSecondaryValue === "status") {
          subDataColor = row.status_color;
          subDataValue = true;
        }

        if (
          this.chartSecondaryValue === "engine" ||
          this.chartSecondaryValue === "ref"
        ) {
          if (!subDataFrom && subDataValue) {
            subDataFrom = timestamp;
          }
          if (subDataFrom && !subDataValue) {
            subData.push({
              from: subDataFrom,
              to: timestamp,
              color: subDataColor,
            });
            subDataFrom = null;
          }
          if (
            subDataFrom &&
            subDataValue &&
            i === this.historyResponseData.length - 1
          ) {
            subData.push({
              from: subDataFrom,
              to: timestamp,
              color: subDataColor,
            });
            subDataFrom = null;
          }
        } else if (this.chartSecondaryValue === "status") {
          if (subDataPreviousColor && subDataPreviousColor !== subDataColor) {
            subData.push({
              from: subDataFrom,
              to: timestamp,
              color: subDataPreviousColor + "66",
            });
            subDataFrom = null;
            subDataPreviousColor = null;
          }
          if (
            subDataPreviousColor &&
            i === this.historyResponseData.length - 1
          ) {
            subData.push({
              from: subDataFrom,
              to: timestamp,
              color: subDataPreviousColor + "66",
            });
            subDataFrom = null;
            subDataPreviousColor = null;
          }
          if (!subDataPreviousColor && subDataPreviousColor !== subDataColor) {
            subDataPreviousColor = subDataColor;
            subDataFrom = timestamp;
          }
        }
      }

      this.data = [data[0], data[1], data[2], data[3], data[4]];
      this.subData = subData;
    },
    getSubData() {},
    getYAxis() {
      let chart = this.charts[this.chartPrimaryValue];
      let yAxis = [];

      for (let i = 0; i < chart.config.length; i++) {
        let chartSettings = chart.config[i];

        yAxis.push({
          title: {
            text: chartSettings.title,
          },
          max: chartSettings.maxValue,
          min: chartSettings.minValue,
          minRange: chartSettings.minRange,
          tickAmount: chartSettings.tickAmount,
          tickInterval: chartSettings.tickInterval,
          opposite: chartSettings.opposite || false,
        });
      }

      return yAxis;
    },
    getSeries() {
      let chart = this.charts[this.chartPrimaryValue];
      let series = [];
      let chartCount = chart.config.length === 1;

      if (chartCount) {
        chart = chart.config[0];
      }

      let chartSettings = chart;
      for (let i = 0; i < this.data.length; i++) {
        let data = this.data[i];
        if (!chartCount) {
          chartSettings = chart.config[i];
        }

        if (data.length) {
          series.push({
            type: chartSettings.type,
            name:
              chartSettings.name +
              (this.chartPrimaryValue === "temp" ? " " + i : ""),
            lineWidth: 1,
            color: chartSettings.color || undefined,
            connectNulls: chartSettings.connectNulls,
            fillOpacity: chartSettings.fillOpacity || 1,
            tooltip: {
              valueSuffix: " " + chartSettings.title,
            },
            yAxis: chartSettings.yAxis || 0,
            data: data,
          });
        }
      }

      return series;
    },
  },
};
</script>
