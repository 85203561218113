<template>
  <div class="c-modal-overlay">
    <div class="c-modal warning-alarm-modal">
      <div class="c-modal-body py-3">
        <div class="mb-2">
          <i class="fas fa-exclamation-triangle warning-icon"></i>
        </div>
        <div class="object-num">
          <h6>{{ alarmData.priority_name }}</h6>
        </div>
        <div class="warning-content">
          {{ trans.text }}
        </div>
      </div>
      <div class="c-modal-footer">
        <button class="btn btn-light w-100 mr-2 py-3" @click="accept">
          <i class="fas fa-exclamation fa-sm mr-1 text-success"></i>
          {{ trans.close }}
        </button>
        <button class="btn btn-light w-100 py-3" @click="goToObject">
          <i class="fas fa-car mr-1 text-danger"></i>
          {{ trans.close_to_object }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import soundUrl from '../../assets/audio/woop-woop.mp3';
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    alarmData: Object
  },
  data() {
    return {
      alarmSound: new Audio(soundUrl)
    }
  },
  computed: {
    ...mapGetters(["translations"]),
    trans() {
      return this.translations.vehicles.alarm;
    },
  },
  mounted() {
    this.audio();
  },
  methods: {
    ...mapActions(["panTo"]),
    goToObject() {
      this.saveAlertedObject()
      this.$emit('modalClosed')
      this.alarmSound.loop = false

      this.panTo({
        lat: this.alarmData.lat,
        lon: this.alarmData.lon,
        course: this.alarmData.course,
      });

      this.$audit({object_id: this.alarmData.vid, action: 'alarm_go_to_object'});
    },
    accept() {
      this.saveAlertedObject()
      this.$emit('modalClosed');
      this.alarmSound.loop = false;

      this.$audit({object_id: this.alarmData.vid, action: 'alarm_close'});
    },
    audio() {
      this.alarmSound.play()
      this.alarmSound.loop = false
    },
    saveAlertedObject() {
      localStorage.setItem('alarm_' + this.alarmData.vid, this.alarmData.txdt);
    }
  }
}
</script>
