/**
 *  SSE Connection Module
 *  useEvents           are required to mount inside Vuex store
 *  eventsBuildQuery    builds URL for SSE
 *  eventsConnect       implements connection
 *  eventsStop          implements disconnection
 */

export const SSE_ACTION_CONFIGURE = "SSE_ACTION_CONFIGURE"
export const SSE_ACTION_CONNECT = "SSE_ACTION_CONNECT"
export const SSE_ACTION_SUBSCRIBE = "SSE_ACTION_SUBSCRIBE"
export const SSE_ACTION_STOP = "SSE_ACTION_STOP"
export const SSE_ACTION_UPDATE_MAP_IDS = "SSE_ACTION_UPDATE_MAP_IDS"
export const SSE_MUTATION_SIGNAL = "SSE_MUTATION_SIGNAL"
export const SSE_MUTATION_STOP = "SSE_MUTATION_STOP"


export class EventSubscriptions {

    #subscriptions = {};

    /**
     * @param {string} endpoint
     * @param {string[]} events
     * @param {{string: string}|null} options
     */
    subscribe(endpoint, events, options=null) {
        this.#subscriptions[endpoint] = {
            events,
            options
        }
    }

    unsubscribe(endpoint) {
        delete this.#subscriptions[endpoint];
    }

    getEndpoints() {
        return Object.keys(this.#subscriptions);
    }

    getEvents() {
        const events = new Set();
        for (let key in this.#subscriptions) {
            for (let event of this.#subscriptions[key].events) {
                events.add(event)
            }
        }
        return [...events];
    }

    getOptions() {
        let options = {}
        for (let key in this.#subscriptions) {
            if (!(this.#subscriptions[key].options instanceof Object)) continue;
            if (Array.isArray(this.#subscriptions[key].options)) continue;
            for (let oKey in this.#subscriptions[key].options) {
                options[`${key}.${oKey}`] = this.#subscriptions[key].options[oKey]
            }
        }
        return options
    }

}

/**
 * Request SSE module for Vuex
 * @returns {{
 *   mutations: {[key: string]: (x: *) => void},
 *   state: {[key: string]: *},
 *   getters: {[key: string]: (x: *) => *},
 *   actions: {[key: string]: (x: *) => Promise<void>},
 * }} Vuex raw module interface
 */
export default function useEvents() {
    /** @type {window.EventSource} */
    let eventSource = null
    /** @type {targetURL: string, token: string} */
    let serverConfig = null
    /** @type {EventSubscriptions} */
    let subscriptions = null

    return {
        state: {
        },
        actions: {
            [SSE_ACTION_CONFIGURE](context, {targetURL, token}) {
                serverConfig = { targetURL, token }
            },

            [SSE_ACTION_CONNECT](context) {
                if (serverConfig === null) {
                    throw new Error("connection not configured property, use eventsConfigure function before start")
                }
                if (subscriptions === null) {
                    throw new Error("subscriptions not configured property, use eventsSubscribe function before start")
                }

                if (eventSource !== null) {
                    eventSource.close()
                    eventSource = null
                }
                const target = new URL(serverConfig.targetURL)
                target.searchParams.append('subscriptions', subscriptions.getEndpoints().join())
                target.searchParams.append('token', serverConfig.token)

                let options = subscriptions.getOptions();
                for (let key in options) {
                    target.searchParams.append(key, options[key]);
                }

                eventSource = new window.EventSource(target, {
                    withCredentials: false,
                })

                for (let source of subscriptions.getEvents()) {
                    eventSource.addEventListener(source, event => {
                        context.commit(SSE_MUTATION_SIGNAL, {event: event.type, data: JSON.parse(event.data)})
                    })
                }
            },

            [SSE_ACTION_SUBSCRIBE](context, { subscriptions: value }){
                if (!(value instanceof EventSubscriptions)) {
                    throw new Error("subscription is not an EventSubscriptions")
                }
                subscriptions = value;
                context.dispatch(SSE_ACTION_CONNECT);
            },
            [SSE_ACTION_STOP](context){
                if (eventSource !== null) {
                    eventSource.close()
                    eventSource = null
                    context.commit(SSE_MUTATION_STOP)
                }
            },
        },
        mutations: {
            [SSE_MUTATION_SIGNAL](state, {event, data}) {
                state[event] = data
            },
            [SSE_MUTATION_STOP](state) {
                for (let key of Object.keys(state)) {
                    delete state[key]
                }
            }
        },
        getters: {
        }
    }
}

/**
 * Configure connection before start
 * @param {function} dispatch of Vuex
 * @param {string} targetURL SSE base URL
 * @param {string} token current user token
 */
export function eventsConfigure(dispatch, targetURL, token) {
    dispatch(SSE_ACTION_CONFIGURE, {targetURL, token})
}

/**
 * Subscription skeleton for SSE connection
 * @param {function} dispatch of Vuex
 * @param {EventSubscriptions} subscriptions which endpoints to subscribe?
 */
export function eventsSubscribe(dispatch, subscriptions) {
    dispatch(SSE_ACTION_SUBSCRIBE, { subscriptions })
}

/**
 * Stop subscription skeleton, close SSE connection and free state.
 * @param {function} dispatch
 */
export function eventsStop(dispatch) {
    dispatch(SSE_ACTION_STOP)
}
